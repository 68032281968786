import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Buffer } from 'buffer';
import { useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { ENV } from '@Utils/envTools';
import { getSetting } from '@Utils/userSettings';
import styled from '@emotion/styled';

import { PASSWORD_EXIPRE_TIME } from '@Utils/generalConstants';
import authenticateUser from '@Utils/auth/authenticateHandler';
import {
  userInfoState,
  fullSpinnerState,
  authMessageState,
  selectedPatientState,
  currentPatientListState,
  patientListCacheState,
  showDisclaimerState,
  showResetPasswordState,
  idleTimeoutState,
  viewerState,
  unsavedState,
  rolodexState
} from '@Utils/atoms';
import { getCookieValue, refreshScanTimestamp } from '@Utils/common';
import { setCookies } from '@Utils/auth/userInfoUtils';
import VersionInfoCard from '@Components/UserSettings/VersionInfoCard';
import { Button, Error } from '@Components/NeosomaUI';

const StyledButton = styled(Button)`
  width: 100%;
`;

const Form = styled.form`
  width: 390px;
  height: fit-content;
  & h2 {
    margin-bottom: 13px;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  line-height: 17px;
`;

const Input = styled.input`
  height: 40px;
  background: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.submarine};
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 40px;
  padding: 0 20px;
`;

const GuidTextContainer = styled.div`
  margin-top: 45px;
  margin-bottom: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & .info-button {
    margin-top: 10px;
    height: 26px;
  }
`;

function resetRememberMe(userId) {
  if (!userId) return;
  const rememberMe =
    getSetting(userId, 'rememberMe') === undefined
      ? true
      : getSetting(userId, 'rememberMe');
  // remove user from local storage if remember me is not checked.
  if (!rememberMe) localStorage.removeItem('user');
}

function LoginForm({ isAuth0 }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const setShowResetPassword = useSetRecoilState(showResetPasswordState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const setIsLoading = useSetRecoilState(fullSpinnerState);
  const setIdleTimeout = useSetRecoilState(idleTimeoutState);
  const setShowDisclaimer = useSetRecoilState(showDisclaimerState);
  const [authMessages, setAuthMessages] = useRecoilState(authMessageState);

  const resetuserInfo = useResetRecoilState(userInfoState);
  const resetSelectedPatient = useResetRecoilState(selectedPatientState);
  const resetPatientList = useResetRecoilState(currentPatientListState);
  const resetIdleTimeout = useResetRecoilState(idleTimeoutState);
  const resetViewer = useResetRecoilState(viewerState);
  const resetShowDisclaimer = useResetRecoilState(showDisclaimerState);
  const resetShowResetPassword = useResetRecoilState(showResetPasswordState);
  const resetUnsaved = useResetRecoilState(unsavedState);
  const resetPatientListCache = useResetRecoilState(patientListCacheState);
  const resetRolodex = useResetRecoilState(rolodexState);

  function checkRememberMe(userId) {
    const rememberMe =
      getSetting(userId, 'rememberMe') === undefined
        ? true
        : getSetting(userId, 'rememberMe');
    if (!rememberMe) {
      sessionStorage.setItem('memento', true);
      // If remember me is not checked, start idle timeout.
      setIdleTimeout('start');
    }
  }

  const doAuthentication = useMutation(authenticateUser, {
    onSuccess: (data = {}) => {
      const changeDate = new Date(data.password_change_date);
      const today = new Date();
      let apiToken;
      if ('hospital_id' in data && 'id' in data && 'token' in data) {
        apiToken = Buffer.from(
          `${data.hospital_id}.${data.id}:${data.token}`
        ).toString('base64');
      }
      const userData = { ...data, apiToken, username };

      // Reset password if password has expired.
      const mustChangePassword = today - changeDate > PASSWORD_EXIPRE_TIME;
      if (mustChangePassword) {
        // Send userdate for reset password component.
        setShowResetPassword(userData);
        return;
      }

      // If password is valid, set state and cookes then navigate to home page.

      // 2: Set cookies.
      userData.cookies && setCookies(userData.cookies, userData);
      // 3: Set timestamp for image cache.
      refreshScanTimestamp();

      if (userInfo?.loginMessage)
        setAuthMessages([...authMessages, userInfo.loginMessage]);
      // 4: Set user info state.
      setUserInfo(userData);
      // 5: clear is loading.
      setIsLoading(false);
      // 7: update remember me.
      checkRememberMe(userData.id);
      // 9: Navigate to home page.
      if (sessionStorage.getItem('redirect')) {
        navigate('/', { replace: true });
        setTimeout(() => {
          navigate(sessionStorage.getItem('redirect'), { replace: false });
          sessionStorage.removeItem('redirect');
        }, 0);
      } else {
        navigate('/', { replace: true });
      }
    },
    onError: (error) => {
      setIsLoading(false);
      try {
        const data = JSON.parse(error?.message || {});
        if (data?.resData?.message)
          setAuthMessages([...authMessages, data.resData.message]);
      } catch (e) {
        setAuthMessages([
          ...authMessages,
          'Authentication failed please try again.'
        ]);
      }
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!username || !password) {
      setAuthMessages([...authMessages, 'Invalid username or password.']);
      return;
    }
    setAuthMessages([]);
    doAuthentication.mutate({ username, password });
  };

  const submitEnabled = !doAuthentication.isLoading;
  useEffect(() => {
    setIsLoading(doAuthentication.isLoading);
    setShowDisclaimer('hide');
  }, [doAuthentication.isLoading, setIsLoading, setShowDisclaimer]);

  // On component mount, reset all states.
  useEffect(() => {
    if (userInfo?.loginMessage)
      setAuthMessages([...authMessages, userInfo.loginMessage]);
    resetUnsaved();
    resetRememberMe(userInfo?.id);
    localStorage.removeItem('user');
    resetuserInfo();

    // For an edge case state was not reset, so explicitly clearing it here. NUI-266 Dec 2023
    setUserInfo(userInfoState?.defaultState);
    resetSelectedPatient();
    resetPatientList();
    resetPatientListCache();
    resetIdleTimeout();
    resetViewer();
    resetShowDisclaimer();
    resetShowResetPassword();
    resetRolodex();

    localStorage.removeItem('showDisclaimer');
    sessionStorage.removeItem('isUnSaved');
    sessionStorage.removeItem('memento');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resetPatientList,
    resetPatientListCache,
    resetSelectedPatient,
    resetuserInfo,
    resetViewer,
    resetShowDisclaimer,
    resetShowResetPassword,
    resetIdleTimeout,
    resetUnsaved,
    resetRolodex
  ]);

  let timeoutFunction;
  const configLoaded = ENV.CLIENT_ID !== undefined;
  if (window.location.hostname.startsWith('redirect.')) {
    // We shouldn't be here - don't enable the login form...
    const lastLogin = getCookieValue('lastLogin');
    if (lastLogin && !lastLogin.startsWith('redirect.')) {
      const subDomain = lastLogin.split(`.`).slice(-2).join(`.`);
      document.cookie = `oneTimeError=UNEXPECTED_REDIRECT_ERROR; domain=.${subDomain}; path=/`;
      const port = lastLogin === 'app.neosomalabs.net' ? ':8443' : '';
      const uri = `https://${lastLogin}${port}/login`;
      window.location.replace(uri);
    } else if (authMessages.length === 0) {
      setAuthMessages([
        'This is unexpected... We cannot redirect you to the proper login page. Please contact Neosoma Support.'
      ]);
    }
  } else if (!timeoutFunction) {
    timeoutFunction = setTimeout(() => {
      if (window.env_missing) {
        if (authMessages.length === 0) {
          setAuthMessages([
            `This doesn't appear to be a valid starting page - are you sure you're in the right place?`
          ]);
        }
      }
    }, 500);
  }

  if (isAuth0) {
    return <span />;
  }

  // Render and clear any location state authMessage
  if (location.state?.authMessage) {
    setAuthMessages([location.state.authMessage]);
    navigate(null, { replace: true, state: undefined });
  }

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <h2
          style={{
            marginBottom: !authMessages[authMessages.length - 1]
              ? '45px'
              : '13px'
          }}
        >
          Log in
        </h2>
        {authMessages[authMessages.length - 1] && (
          <Error
            errorMessage={authMessages && authMessages[authMessages.length - 1]}
            showIcon
            visible={!!authMessages[authMessages.length - 1]}
          />
        )}
        <Label htmlFor="username">
          Username
          <Input
            type="text"
            id="username"
            name="username"
            disabled={!submitEnabled}
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </Label>
        <Label htmlFor="username">
          Password
          <Input
            type="password"
            id="password"
            name="password"
            disabled={!submitEnabled}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Label>
        <StyledButton
          variant="secondary"
          type="submit"
          disabled={!submitEnabled || !configLoaded}
        >
          {!doAuthentication.isLoading && 'Log In'}
          {doAuthentication.isLoading && 'Loading...'}
        </StyledButton>
      </Form>
      <GuidTextContainer>
        <VersionInfoCard />
      </GuidTextContainer>
    </div>
  );
}

LoginForm.propTypes = {
  isAuth0: PropTypes.bool.isRequired
};

export default LoginForm;
