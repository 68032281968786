/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import { useEffect } from 'react';
import { shape, string } from 'prop-types';
import styled from '@emotion/styled';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  selectedPatientState,
  patientInfoState
} from '@Utils/atoms';

import getAuthCookies from '@Utils/api/getAuthCookies';
import {
  getAge,
  getSlashedDatetime,
  generateScanImageFilename,
  maskPII
} from '@Utils/common';
import { Button } from '@Components/NeosomaUI';
import InlineSpinner from '@Components/NeosomaUI/InlineSpinner/InlineSpinner';
import Feature from '@Utils/FeatureFlags';
import { RxOpenInNewWindow } from "react-icons/rx";
import ImageCard from '../../patients/Components/ImageCard';

const ImageViewerContainer = styled.div`
  max-width: 409px;
  min-width: 409px;
  flex-grow: 0;
  border-top: 1px solid ${({ theme }) => theme.palette.lightsilver};
  padding: 17px 16px;
`;
const Heading = styled.div`
  line-height: 19px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightsilver};
  padding-bottom: 14px;
  &h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }
`;
const PatientInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.antiflashwhite};
  border: 1px solid ${({ theme }) => theme.palette.lightsilver};
  border-radius: 3px;
  padding: 12px;
  margin-top: 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
const PatientInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  column-gap: 40px;
  & strong {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }
  min-height: 15px;
  & div {
    flex: 1;
  }
  & div:last-child {
    text-align: end;
  }
`;
const ImagesContainer = styled.div`
  display: flex;
  column-gap: 13px;
  margin-top: 24px;
  flex-wrap: wrap;
  row-gap: 13px;
`;
const ImageContainer = styled.div`
  cursor: pointer;
`;
const OpenIconButton = styled(Button)`
  border-radius: 1px;
  border: 0.5px solid var(--neutrals-n-600, #D6D6D6);
  background: var(--neutrals-n-400, #ECECEC);
  color: #6B6B6B;
  height: 29px;
  width: 31px;
  padding: 0;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// *----------------------------*
// |  COMPONENT :: PatientInfo  |
// *----------------------------*
function PatientInfo({ pData }) {
  const navigate = useNavigate();
  const {
    first_name,
    last_name,
    dob = '',
    age_type,
    sex,
    diagnosis,
    diagnosis_grade,
    patient_iuid,
    id,
    hospital_id,
    latest_study_id,
    active_treatments = ''
  } = pData;
  const displayDOB = (() => {
    if (!dob || age_type !== 0) return 'Unknown';
    const dobStr = (
      dob instanceof Date ? dob.toISOString() : dob.toString()
    ).substring(0, 10);
    const [year, month, day] = dobStr.split('-');
    return `${month}/${day}/${year}`;
  })();
  const [patientInfo] = useRecoilState(patientInfoState);

  const getTreatmentList = () => {
    if (active_treatments) {
      return active_treatments
        .split(',')
        .filter((item, index, arr) => arr.indexOf(item) === index)
        .map((rec) => <div key={rec}>{rec.replace(/^(.*?) \/ /, '')}</div>);
    }
    return '';
  };

  const getImages = () => {
    if (patientInfo[id]) {
      const imageList = (() => {
        // "13NCJKUCID/931USXYWZH/5610QRP75L/p13NCJKUCID_931USXYWZH_5610QRP75L_preview.png"
        const f = generateScanImageFilename(
          patientInfo[id].id,
          patientInfo[id].hospital_id,
          patientInfo[id].latest_study_id
        );
        let imageData = { images: [] };
        if (f && patientInfo[id].latest_study_id) {
          const study_id = patientInfo[id].latest_study_id;
          imageData = {
            ...imageData,
            images: [
              { id: study_id, name: 'T1c', filename: `${f.replace('.png', '_t1c.png')}` },
              { id: study_id, name: 'FLAIR', filename: `${f.replace('.png', '_flair.png')}` },
              { id: study_id, name: 'T1', filename: `${f.replace('.png', '_t1.png')}` },
              { id: study_id, name: 'T2', filename: `${f.replace('.png', `_t2.png`)}` }
            ],
            overlayImage: f.replace('.png', '_seg.png')
          };
        }
        return imageData;
      })();
      return (
        <ImagesContainer>
          {imageList.images.map((rec) => (
            <ImageContainer
              role="image"
              key={rec.filename}
              style={{ position: 'relative' }}
              onClick={() => {
                navigate(`/viewer/${rec.id}`, {state: {initiator: 'ImageViewer'}});
              }}
            >
              <ImageCard
                studyId={latest_study_id}
                hospitalId={hospital_id}
                image={rec.filename}
                toolTipText="Click to open"
                overlay={imageList.overlayImage}
                disableViewerDblClick
                showMenu={false}
                showLabelBar={false}
                maxWidth="182px"
              />
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: 'absolute', right: '10px', top: '10px' }}
              >
                <g id="Group 189">
                  <path
                    id="Vector"
                    d="M1 14L13.2455 1"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M1 1H13.2455V14"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
              <div
                style={{
                  color: 'white',
                  bottom: '8px',
                  left: '11px',
                  position: 'absolute'
                }}
              >
                {rec.name}
              </div>
            </ImageContainer>
          ))}
        </ImagesContainer>
      );
    }
    return '';
  };

  return (
    <>
      <PatientInfoContainer>
        <PatientInfoRow>
          <div>{`${maskPII(last_name)}${
            last_name && first_name ? ', ' : ''}${
            maskPII(first_name)}`}</div>
          <div>{`AGE: ${getAge(dob, age_type) || '--'} YO ${sex}`}</div>
        </PatientInfoRow>
        <PatientInfoRow>
          <div>
            {`MRN: `}
            {patient_iuid && (
              <span style={{ fontSize: '13px' }}>{maskPII(patient_iuid)}</span>
            )}
          </div>
          <div>{age_type === 0 && `DOB: ${maskPII(displayDOB)}`}</div>
        </PatientInfoRow>
        <PatientInfoRow />
        <PatientInfoRow>
          {diagnosis && (
            <div>
              <strong>Diagnosis:</strong>
              {` ${diagnosis}`}
            </div>
          )}
          <Feature name="show-treatment-list" isOff>
            <div />
          </Feature>
          <Feature name="show-treatment-list">
            <div>
              <strong>Current Treatment(s):</strong>
            </div>
          </Feature>
        </PatientInfoRow>
        <Feature name="show-treatment-list">
          <PatientInfoRow>
            <div style={{ flex: 'initial' }}>
              <strong>Stage:</strong>
              {` ${diagnosis_grade}`}
            </div>
            <div>{getTreatmentList()}</div>
          </PatientInfoRow>
        </Feature>
      </PatientInfoContainer>
      <>
        {!getImages() && (
          <ImagesContainer>
            <InlineSpinner />
          </ImagesContainer>
        )}
        {getImages()}
      </>
    </>
  );
}

PatientInfo.defaultProps = { pData: {} };

PatientInfo.propTypes = {
  pData: shape({
    first_name: string,
    last_name: string,
    dob: string,
    sex: string,
    diagnosis: string,
    diagnosis_grade: string,
    patient_iuid: string
  })
};

function ImageViewer() {
  const navigate = useNavigate();
  const [selectedPatient = {}] = useRecoilState(selectedPatientState);
  const [patientInfo, setPatientInfo] = useRecoilState(patientInfoState);

  useEffect(() => {
    const thisPatient = selectedPatient[selectedPatient?.currentPatient];
    const id = thisPatient?.id;
    if (selectedPatient?.currentPatient && !patientInfo[id]) {
      // Get patient details (TODO: Remove delay)
      getAuthCookies().then(() => {
        setPatientInfo({
          ...patientInfo,
          [id]: { ...thisPatient }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient?.currentPatient]);

  const handleOpen = () => {
    const thisPatient = selectedPatient[selectedPatient?.currentPatient];
    const id = thisPatient?.id;
    if(!id) return;
    navigate(`/patients/${id}`, {state: {from: window.location.href}});
  }
  
  if (selectedPatient?.currentPatient) {
    const thisPatient = selectedPatient[selectedPatient.currentPatient];
    const {
      first_name: firstName,
      last_name: lastName,
      latest_scan_date: latestScanDate
    } = thisPatient;
    const descriptor =
      thisPatient.sort_type === 2 ? 'New Imaging' : 'Latest Images';

    return (
      <ImageViewerContainer>
        <Heading>
        <HeaderContainer>
          <h3>
            {`${maskPII(lastName)}${
              lastName && firstName ? ', ' : ''}${
              maskPII(firstName)}`}
            {latestScanDate &&
              ` - ${descriptor} ${getSlashedDatetime(latestScanDate)}`}
          </h3>
          <OpenIconButton type="button" variant="primary-inverse" onClick={handleOpen} title="View Patient Details"><RxOpenInNewWindow size="20px" /></OpenIconButton>
          </HeaderContainer>
        </Heading>
        <PatientInfo pData={thisPatient} />
      </ImageViewerContainer>
    );
  }
  return (
    <ImageViewerContainer>
      <Heading>
        <h3>No Imaging Selected</h3>
      </Heading>
    </ImageViewerContainer>
  );
}

ImageViewer.defaultProps = {};

ImageViewer.propTypes = {};

export default ImageViewer;
