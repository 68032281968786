/* eslint-disable camelcase */
import { useState, useRef, useEffect } from 'react';
import PropTypes, { oneOfType, arrayOf, node } from 'prop-types';
import { Link, Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { useRecoilValue, useRecoilState } from 'recoil';

import { ENV } from '@Utils/envTools';
import {
  userInfoState,
  showResetPasswordState,
  cookieRefreshState,
  avatarTimeStampState
} from '@Utils/atoms';
import {
  DropDown,
  DropdownItem,
  Flex,
  Typography
} from '@Components/NeosomaUI';
import getAuthCookies from '@Utils/api/getAuthCookies';
import sendPasswordChangeEmail from '@Utils/api/sendAuth0PasswordResetEmail';

import neosomaLogo from '../assets/login-logo.png';

const NAVIGATION_ITEMS = [
  {
    id: 'home',
    title: 'Home',
    link: '/'
  },
  {
    id: 'patient-search',
    title: 'Patient Search',
    link: '/patient-search'
  }
];

const NavigationContainer = styled(Flex)`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.palette.blackpearl};
`;

// TODO - get an SVG of this image!
const NavigationLogo = styled.img`
  max-width: 125px;
`;

const NavigationItem = styled(Link)`
  color: ${({ theme }) => theme.palette.white};
  text-decoration: none;
  transition: 0.5s all;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 0px;
    height: 5px;
    left: 50%;
    top: 32px;
    background-color: ${({ theme }) => theme.palette.neosomaOrange};
    transition: all ease-in-out 0.2s;
  }
  &:hover {
    color: ${({ theme }) => theme.palette.neosomaOrange};
    &:after {
      width: 100%;
      left: 0;
    }
  }
`;

const UserAvatarContainer = styled(Flex)`
  position: relative;
  width: 35px;
  height: 35px;
`;

const Avatar = styled.img`
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 35px;
`;

const Initials = styled(Flex)`
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  background: ${({ theme }) => theme.palette.neosomaOrange};
`;

const StyledDropDown = styled(DropDown)`
  min-width: 0;
  top: 45px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.palette.neosomaOrange};
  }
`;

const StyledButton = styled.button`
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-align: left;
  &:hover {
    color: ${({ theme }) => theme.palette.neosomaOrange};
  }
`;

// eslint-disable-next-line react/prop-types
function PasswordReset({ children }) {
  const { username } = useRecoilValue(userInfoState) || {};
  const [showResetPassword, setShowResetPassword] = useRecoilState(
    showResetPasswordState
  );

  const handleChangePasswordClick = async () => {
    try {
      setShowResetPassword(!showResetPassword);
      await sendPasswordChangeEmail(username);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error sending password change email', error);
    }
  };

  return (
    <button type="button" onClick={handleChangePasswordClick}>
      {children}
    </button>
  );
}

function UserInitials({ firstName, lastName, displayStyle }) {
  const initials =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  return (
    <Initials
      alignItems="center"
      justifyContent="center"
      style={{ display: displayStyle }}
    >
      <Typography variant="h2" text="white">
        {initials}
      </Typography>
    </Initials>
  );
}

UserInitials.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  displayStyle: PropTypes.string.isRequired
};

function UserAvatar({ image, firstName, lastName, sso }) {
  const userAvatarElm = useRef(null);
  const avatarImgElm = useRef(null);
  const [showResetPassword, setShowResetPassword] = useRecoilState(
    showResetPasswordState
  );
  const avatarTimeStamp = useRecoilValue(avatarTimeStampState);
  const [settled, setSettled] = useState(false);
  const [showMenu, setShowMenu] = useState(undefined);
  // Error state tracking
  const canRetry = useRef(true);
  const errorCount = useRef(0);
  const lastCookieRefresh = useRef(null);
  const cookieRefreshing = useRecoilValue(cookieRefreshState);
  const [imageLoadFailed, setImageLoadFailed] = useState(false);
  const auth0Settings = ENV.OAUTH2;
  const isAuth0 = auth0Settings.organization !== undefined;

  const handleError = () => {
    // first image load attempt fails. (either cookie is stale or image is broken)
    //   fetch cookies and wait.
    //     s: got new cookies
    //       retry image load.
    //         s: all good.( we wont know this happened) as the error handler will not be called.
    //           new fail: cookie if failure happened within 7 seconds of the last cookie refresh.
    //             true: image is broken.
    //             false: can retry again in 5min.
    //         f: image is broken (not a problem due to stale cookie)
    //     f: cookie call failed so not sure at this point if image is broken can retry again in 5min.
    errorCount.current += 1;
    if (errorCount.current > 1 && canRetry.current) {
      // If we have already retried once or more, check if the error happened within 7 seconds of the last cookie refresh.
      if (Date.now() - lastCookieRefresh.current < 7000) {
        // Image is broken. We wont retry.
        canRetry.current = false;
        setImageLoadFailed(true);
        setSettled(true);
        return;
      }
      if (Date.now() - lastCookieRefresh.current > 1000 * 60 * 5) {
        // If the last cookie refresh was more than 5 minutes ago, we can retry.
        errorCount.current = 1;
      }
    }
    if (errorCount.current === 1 && canRetry.current) {
      getAuthCookies()
        // SUCCESS
        .then(() => {
          // we got cookies. image will try to reload.
          lastCookieRefresh.current = Date.now();
          canRetry.current = true;
        })
        // FAILED
        .catch((err) => {
          // failed to get cookie but we can retry as we don't know if the image is broken at this point.
          lastCookieRefresh.current = Date.now();
          canRetry.current = true;
        });
    }
  };

  const handleResetPassword = () => {
    setShowResetPassword(!showResetPassword);
  };
  const isBrokenImage = !!imageLoadFailed;
  const waitForCookieRefresh =
    cookieRefreshing.refreshing && !!errorCount.current;
  const showImage = settled && !isBrokenImage && !waitForCookieRefresh;

  useEffect(() => {
    canRetry.current = true;
    errorCount.current = 0;
    setSettled(false);
    setImageLoadFailed(false);
    lastCookieRefresh.current = null;
  }, [avatarTimeStamp]);

  let img_url = !waitForCookieRefresh ? `${image}?ts=${avatarTimeStamp}` : '';
  if (sso) {
    img_url = image;
  }
  return (
    <Flex
      ref={userAvatarElm}
      gap="12px"
      alignItems="center"
      onClick={() => setShowMenu(!showMenu)}
      type="button"
      role="button"
      tabIndex={0}
      style={{ paddingLeft: '10px', cursor: 'pointer' }}
    >
      <UserAvatarContainer>
        {(!settled || (isBrokenImage && settled)) && (
          <UserInitials
            firstName={firstName}
            lastName={lastName}
            displayStyle="flex"
          />
        )}
        <Avatar
          ref={avatarImgElm}
          src={img_url}
          alt={`${firstName} ${lastName}`}
          style={{ display: 'flex', opacity: showImage ? 1 : 0 }}
          onError={handleError}
          onLoad={() => {
            avatarImgElm.current.complete && setSettled(true);
          }}
        />
      </UserAvatarContainer>
      <StyledDropDown
        buttonText={`${firstName} ${lastName}`}
        variant="plain"
        position="right"
        hideOnSelect
        externalShowMenu={showMenu}
        setExternalShowMenu={setShowMenu}
        externalRef={userAvatarElm}
      >
        <DropdownItem>
          <StyledLink to="/settings">Settings</StyledLink>
        </DropdownItem>
        {!sso && (
          <DropdownItem>
            {!isAuth0 && (
              <StyledButton type="button" onClick={handleResetPassword}>
                Change Password
              </StyledButton>
            )}
            {isAuth0 && <PasswordReset>Change Password</PasswordReset>}
          </DropdownItem>
        )}
        <DropdownItem>
          <StyledLink to="/login">Log Out</StyledLink>
        </DropdownItem>
      </StyledDropDown>
    </Flex>
  );
}

UserAvatar.propTypes = {
  image: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  sso: PropTypes.bool
};

UserAvatar.defaultProps = {
  image: null,
  firstName: '',
  lastName: '',
  sso: false
};

export default function Navigation({ children }) {
  const [waitForEnv, setWaitForEnv] = useState(true);
  const { id, first_name, last_name, home_hospital_id, sso, sso_avatar } =
    useRecoilValue(userInfoState) || {};
  let avatar_uri = `${ENV.CONTENT_URL}/${home_hospital_id}/_avatars/user_${id}.png`;
  if (sso && sso_avatar) {
    avatar_uri = sso_avatar;
  }

  useEffect(() => {
    // Give ENV a chance to load before checking for contents
    if (waitForEnv) setTimeout(() => setWaitForEnv(false), 100);
  }, [waitForEnv]);

  return (
    <>
      <NavigationContainer
        justifyContent="space-between"
        data-cy="navbar-menu-avatar"
      >
        <Flex gap="50px" alignItems="center">
          <Flex alignItems="center">
            <Link to="/">
              <NavigationLogo src={neosomaLogo} alt="neosoma" />
            </Link>
          </Flex>
          {NAVIGATION_ITEMS.map((navItem) => (
            <NavigationItem as={Typography} key={navItem.id} to={navItem.link}>
              <Typography variant="h3">{navItem.title}</Typography>
            </NavigationItem>
          ))}
        </Flex>
        <Flex>
          <UserAvatar
            firstName={first_name}
            lastName={last_name}
            image={avatar_uri}
            sso={sso}
          />
        </Flex>
        <Outlet />
      </NavigationContainer>
      {children}
    </>
  );
}

Navigation.propTypes = {
  children: PropTypes.node
};

Navigation.defaultProps = {
  children: oneOfType([arrayOf(node), node]).isRequired
};
