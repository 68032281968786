import { forwardRef, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

import { Flex, Typography } from '@Components/NeosomaUI';
import ActionMenu from '@Components/NeosomaUI/ActionMenu';
import { selectedPatientState } from '@Utils/atoms';
import { userPrivs } from '@Utils/auth/userInfoUtils';
import setStudyCategory from '@Utils/api/setStudyCategory';
import { getDate } from '@Utils/common';
import ScanImage from './ScanImage';
import ExportModal from './ExportModal';

const TYPES = { Baseline: 'baseline', Nadir: 'nadir' };

const ImageCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.submarine};
  border-radius: 2px 2px 0 0;
  font-size: 0;
  position: relative;
  max-width: ${({ maxWidth }) => maxWidth || '184px'};
  min-width: 122px;
  .hidden {
    clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  }
  .overlay {
    z-index: 20;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 25%;
    pointer-events: none;
  }
`;

const ImageCardHeader = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.platinum};
  padding: 8px 4px 8px 13px;
  @media print {
    z-index: 3;
  }
`;

const Button = styled('button')`
  display: flex;
  font-size: 12px;
  border: none;
  width: 100%;
  background-color: transparent;
  justify-content: flex-start;
  color: ${({ theme }) => theme.palette.mineshaft};
  padding: 8px 10px;
  white-space: nowrap;
  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.palette.antiflashwhite};
  }
  &:disabled {
    color: ${({ theme }) => theme.palette.bombay};
  }
`;

const DropdownItemButton = forwardRef(
  ({ onClick, dimissMenu, children, ...props }, ref) => {
    const handleClick = (e) => {
      dimissMenu();
      onClick(e);
    };
    return (
      <Button {...props} onClick={handleClick} forwardedRef={ref}>
        {children}
      </Button>
    );
  }
);
DropdownItemButton.defaultProps = {
  children: null,
  onClick: () => {
    /* No Op */
  },
  dimissMenu: () => {
    /* No Op */
  }
};

DropdownItemButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  dimissMenu: PropTypes.func
};

export default function ImageCard({
  id,
  status = 10,
  studyId,
  hospitalId,
  _patientId,
  title,
  subTitle,
  image,
  toolTipText,
  showInteractiveLabel,
  overlay,
  showMenu = true,
  showLabelBar = true,
  disableViewerDblClick = false,
  maxWidth,
  handleClick
}) {
  const navigate = useNavigate();
  const [showExport, setShowExport] = useState(false);
  const canEditPatient = userPrivs.EDIT_PATIENT;
  const canExport = userPrivs.EXPORT_STUDY;
  const [selectedPatient, setSelectedPatient] =
    useRecoilState(selectedPatientState);

  const patientId = _patientId || selectedPatient.currentPatient;

  const handleOpenClick = () => {
    if (selectedPatient.currentStudy !== studyId) {
      setSelectedPatient({ ...selectedPatient, currentStudy: studyId });
    }
    navigate(`/viewer/${studyId}`, {
      state: { initiator: 'ImageCard', from: window.location.href }
    });
  };

  const handleImgClick = (e) => {
    if (e.detail === 2 && !disableViewerDblClick) {
      handleOpenClick();
    } else {
      setTimeout(() => {
        // delay the click event to allow for double click to display viewer.
        // User can avoid seeing the scroll.
        handleClick(e);
      }, 150);
    }
  };

  const handleSetImageType = (type, action = 'set') => {
    const studyKeys = {
      [TYPES.Baseline]: 'baseline_study_id',
      [TYPES.Nadir]: 'nadir_study_id'
    };
    // update local state.
    setSelectedPatient({
      ...selectedPatient,
      [patientId]: {
        ...selectedPatient[patientId],
        [studyKeys[type]]: action === 'set' ? studyId : null
      }
    });
    setStudyCategory(
      patientId,
      studyKeys[type],
      action === 'set' ? studyId : null
    );
  };

  const isBaseline = selectedPatient[patientId].baseline_study_id === studyId;
  const isNadir = selectedPatient[patientId].nadir_study_id === studyId;
  const study = selectedPatient[patientId].studies?.find(
    (s) => s.id === studyId
  );
  const studyDate = getDate(study?.study_date);
  const volumes = study?.segmentations?.map((g) => ({
    label: g.label,
    volume: g.volume
  }));

  const toolTip = (() => {
    if (status !== 10) {
      return '';
    }
    if (disableViewerDblClick) {
      return toolTipText || '';
    }
    return 'Double click to open';
  })();

  return (
    <ImageCardWrapper id={id} maxWidth={maxWidth}>
      <ExportModal
        patientId={patientId}
        studyId={studyId}
        studyDate={studyDate}
        volumes={volumes}
        useShowExport={() => [showExport, setShowExport]}
      />
      {showLabelBar && (
        <ImageCardHeader
          justifyContent="space-between"
          alignItems="center"
          data-test="image-card-header"
        >
          <Flex direction="column">
            <Typography bold>{title}</Typography>
            {subTitle && <Typography variant="small">{subTitle}</Typography>}
          </Flex>
          {showMenu && status === 10 && (
            <ActionMenu>
              <DropdownItemButton type="button" onClick={handleOpenClick}>
                Open
              </DropdownItemButton>

              {canEditPatient ? (
                <DropdownItemButton
                  type="button"
                  onClick={() => {
                    handleSetImageType(
                      TYPES.Baseline,
                      isBaseline ? 'unset' : undefined
                    );
                  }}
                >
                  {isBaseline && (
                    <>
                      <FaTimes />
                      &nbsp;
                    </>
                  )}
                  {`${isBaseline ? 'Unset (Baseline)' : 'Set As Baseline'}`}
                </DropdownItemButton>
              ) : (
                <span />
              )}
              {canEditPatient ? (
                <DropdownItemButton
                  type="button"
                  onClick={() => {
                    handleSetImageType(
                      TYPES.Nadir,
                      isNadir ? 'unset' : undefined
                    );
                  }}
                >
                  {isNadir && (
                    <>
                      <FaTimes />
                      &nbsp;
                    </>
                  )}
                  {`${isNadir ? 'Unset (Nadir)' : 'Set As Nadir'}`}
                </DropdownItemButton>
              ) : (
                <span />
              )}
              {canExport ? (
                <DropdownItemButton
                  type="button"
                  onClick={() => {
                    setShowExport(!showExport);
                  }}
                >
                  Export...
                </DropdownItemButton>
              ) : (
                <span />
              )}
            </ActionMenu>
          )}
        </ImageCardHeader>
      )}
      <ScanImage
        title={toolTip}
        studyId={studyId}
        status={status}
        hospitalId={hospitalId}
        image={image}
        overlay={overlay}
        showInteractiveLabel={showInteractiveLabel}
        handleClick={handleImgClick}
      />
    </ImageCardWrapper>
  );
}

ImageCard.propTypes = {
  id: PropTypes.string,
  status: PropTypes.number,
  studyId: PropTypes.string.isRequired,
  _patientId: PropTypes.string,
  hospitalId: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  toolTipText: PropTypes.string,
  showInteractiveLabel: PropTypes.bool,
  overlay: PropTypes.string,
  showMenu: PropTypes.bool,
  showLabelBar: PropTypes.bool,
  maxWidth: PropTypes.string,
  handleClick: PropTypes.func,
  disableViewerDblClick: PropTypes.bool
};

ImageCard.defaultProps = {
  id: null,
  status: 10,
  _patientId: null,
  hospitalId: null,
  title: null,
  subTitle: null,
  image: null,
  toolTipText: '',
  showInteractiveLabel: false,
  overlay: null,
  showMenu: true,
  showLabelBar: true,
  maxWidth: null,
  handleClick: () => {
    /* No Op */
  },
  disableViewerDblClick: false
};
