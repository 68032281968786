/* eslint-disable camelcase */
import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';
import { FaUpload } from 'react-icons/fa';

import getAuthCookies from '@Utils/api/getAuthCookies';
import {
  userInfoState,
  cookieRefreshState,
  avatarTimeStampState
} from '@Utils/atoms';
import { ENV } from '@Utils/envTools';

import { Typography, Button, Flex } from '@Components/NeosomaUI';
import UserImageUpload from './UserImageUpload';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.white};
  flex: 0;
  gap: 18px;
  height: fit-content;
  padding: 24px 22px;
  width: 189px;
  min-width: 189px;
  height: fit-content;
  border-radius: 3px;
  border: 1px solid var(--neutrals-n-900, #b2bbc2);
`;

const UserAvatarContainer = styled(Flex)`
  position: relative;
  height: 101px;
  width: 101px;
`;

const Avatar = styled.img`
  position: absolute;
  height: 101px;
  width: 101px;
  border-radius: 100%;
`;

const NameField = styled(Typography)`
  color: ${({ theme }) => theme.palette.mineshaft};
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Initials = styled(Flex)`
  width: 101px;
  height: 101px;
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.neosomaOrange};
  & h2 {
    font-size: 52px;
  }
`;

function UserInitials({ firstName, lastName, displayStyle }) {
  const initials =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  return (
    <Initials
      alignItems="center"
      justifyContent="center"
      style={{ display: displayStyle }}
    >
      <Typography variant="h2" text="white">
        {initials}
      </Typography>
    </Initials>
  );
}

UserInitials.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  displayStyle: PropTypes.string.isRequired
};

export default function UserSettingsImage() {
  const canRetry = useRef(true);
  const lastCookieRefresh = useRef(null);
  const avatarImgElm = useRef(null);
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const [settled, setSettled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const errorCount = useRef(0);
  const userInfo = useRecoilValue(userInfoState) || {};
  const avatarTimeStamp = useRecoilValue(avatarTimeStampState);
  const { id, first_name, last_name, home_hospital_id, sso, sso_avatar } =
    userInfo;
  let avatarImgUrl = `${ENV.CONTENT_URL}/${home_hospital_id}/_avatars/user_${id}.png?`;

  if (sso && sso_avatar) {
    avatarImgUrl = sso_avatar;
  }

  const cookieRefreshing = useRecoilValue(cookieRefreshState);

  const handleError = async () => {
    // first image load attempt fails. (either cookie is stale or image is broken)
    //   fetch cookies and wait.
    //     s: got new cookies
    //       retry image load.
    //         s: all good.( we wont know this happened) as the error handler will not be called.
    //           new fail: cookie if failure happened within 7 seconds of the last cookie refresh.
    //             true: image is broken.
    //             false: can retry again in 5min.
    //         f: image is broken (not a problem due to stale cookie)
    //     f: cookie call failed so not sure at this point if image is broken can retry again in 5min.
    errorCount.current += 1;
    if (errorCount.current > 1 && canRetry.current) {
      // If we have already retried once or more, check if the error happened within 7 seconds of the last cookie refresh.
      if (Date.now() - lastCookieRefresh.current < 7000) {
        // Image is broken. We wont retry.
        canRetry.current = false;
        setImageLoadFailed(true);
        return;
      }
      if (Date.now() - lastCookieRefresh.current > 1000 * 60 * 5) {
        // If the last cookie refresh was more than 5 minutes ago, we can retry.
        errorCount.current = 1;
      }
    }
    if (errorCount.current === 1 && canRetry.current) {
      getAuthCookies()
        // SUCCESS
        .then(() => {
          // we got cookies. image will try to reload.
          lastCookieRefresh.current = Date.now();
          canRetry.current = true;
        })
        // FAILED
        .catch((err) => {
          // failed to get cookie but we can retry as we don't know if the image is broken at this point.
          lastCookieRefresh.current = Date.now();
          canRetry.current = true;
        });
    }
  };

  const isBrokenImage = !!imageLoadFailed;
  const waitingForLoad = cookieRefreshing.refreshing;
  const showImage = settled || (!isBrokenImage && !waitingForLoad);

  const updateAvatar = () => {
    setImageLoadFailed(false);
    canRetry.current = true;
    errorCount.current = 0;
    setSettled(false);
  };

  let img_url = `${avatarImgUrl}ts=${avatarTimeStamp}`;
  if (sso && sso_avatar) {
    img_url = avatarImgUrl;
  }

  return (
    <MainContainer>
      <UserAvatarContainer>
        <Avatar
          ref={avatarImgElm}
          src={img_url}
          alt={showImage ? `${first_name} ${last_name}` : ''}
          style={{
            display: settled ? 'flex' : 'none',
            opacity: settled ? 1 : 0
          }}
          onError={handleError}
          onLoad={() => {
            avatarImgElm.current.complete && setSettled(true);
          }}
        />
        {(!settled || (isBrokenImage && settled)) && (
          <UserInitials
            firstName={first_name}
            lastName={last_name}
            displayStyle="flex"
          />
        )}
      </UserAvatarContainer>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
        <NameField>{`${first_name} ${last_name}`}</NameField>
        {!sso && (
          <>
            <UserImageUpload
              showModal={showModal}
              hideModal={() => {
                setShowModal(!showModal);
              }}
              updateAvatar={updateAvatar}
            />
            <Button
              type="button"
              variant="link"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <FaUpload />
              &nbsp;Upload Image
            </Button>
          </>
        )}
      </div>
    </MainContainer>
  );
}
