// import { useRef, useState, Children, cloneElement } from 'react';
// import PropTypes from 'prop-types';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import styled from '@emotion/styled';

import { Typography, Button, Flex } from '@Components/NeosomaUI';
import getString from '@Components/strings';
import { showResetPasswordState, userInfoState } from '@Utils/atoms';
import InputLoginDisclaimer from './InputLoginDisclaimer';
import InputRememberMe from './InputRememberMe';
import InputHidePII from './InputHidePII';
import InputDefaultTab from './InputDefaultTab';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.white};
  flex: 1;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.palette.submarine};
  border-radius: 3px;
  & h1 {
    color: ${({ theme }) => theme.palette.tangerine};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    padding: 24px 22px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.platinum};
  }
  height: fit-content;
  min-height: 410px;
  min-width: 513px;
  & p,
  & button,
  & a {
    font-size: 0.825rem !important;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 55px;
  padding: 24px 22px;
  background-color: #f8f8f8;
`;

const LeftContainer = styled(Flex)`
  width: 100%;
  border-right: 1px solid transparent;
  padding-right: 20px;
`;

const handelDownload = () => {
  const filename = getString('IFU_filename');
  const a = document.createElement('a');
  a.href = getString('IFU_url');
  a.target = '_blank';
  a.rel = 'noopener noreferrer';
  a.download = filename;
  a.click();
};

const SettingsCard = styled(Flex)`
  gap: 18px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.white};
  padding: 16px;
  border-radius: 3px;
  border: 1px solid #dedddd;
  & input {
    min-height: 20px;
  }
`;

export default function UserSettingsControls() {
  const setShowChangePassword = useSetRecoilState(showResetPasswordState);
  const handelChangePassword = () => {
    setShowChangePassword(true);
  };
  const { sso } = useRecoilValue(userInfoState) || {};

  return (
    <MainContainer>
      <h1>User Settings</h1>
      <ContentContainer>
        <Flex direction="row" grow="1">
          <LeftContainer gap="36px" grow="1" direction="column">
            <SettingsCard>
              <Typography variant="h2">Preferences</Typography>
              <InputLoginDisclaimer />
              <InputRememberMe />
              <InputHidePII />
              <InputDefaultTab />
            </SettingsCard>
            <SettingsCard>
              <Typography variant="h2">Other Options</Typography>
              {!sso && (
                <Typography>
                  &bull;&nbsp;
                  <Button
                    type="button"
                    variant="link"
                    onClick={handelChangePassword}
                    style={{
                      height: '21px',
                      width: 'fit-content'
                    }}
                  >
                    Change Password
                  </Button>
                </Typography>
              )}
              <Typography>
                &bull; Download a copy of our IFU:
                <Button
                  type="button"
                  variant="link"
                  onClick={handelDownload}
                  style={{
                    height: '21px',
                    marginLeft: '8px'
                  }}
                >
                  Download
                </Button>
              </Typography>
            </SettingsCard>
          </LeftContainer>
        </Flex>
      </ContentContainer>
    </MainContainer>
  );
}
