/* eslint-disable camelcase */
import { useState } from 'react';
import styled from '@emotion/styled';
import { getAge, getSlashedDate, getSex, maskPII } from '@Utils/common';
import PropTypes, { bool } from 'prop-types';
import { FiChevronLeft, FiUser } from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';

import { Flex, Typography, ConfirmModal } from '@Components/NeosomaUI';
import Feature, { getFeatureFlag } from '@Utils/FeatureFlags';
import PatientActionMenu from './PatientActionMenu';

const HEADER_DATA_PATIENT_ITEMS = [
  {
    title: 'Age',
    value: 'age'
  },
  {
    title: 'Sex',
    formatter: getSex,
    value: 'sex'
  },
  {
    title: 'DOB',
    formatter: getSlashedDate,
    value: 'dob',
    border: true
  },
  {
    title: 'Diagnosis',
    value: 'diagnosis'
  },
  {
    title: 'Stage',
    value: 'diagnosis_grade'
  },
  {
    title: 'Status',
    value: 'status',
    formatter: (status) => 'Active', // Set to always active for now NUI-215
    border: getFeatureFlag('non-mvp-feature')
  },
  {
    title: 'Attending',
    value: 'attending_doc'
  },
  {
    title: 'Referring',
    value: 'referring_doc'
  }
];

const HeaderItemWrapper = styled(Flex)`
  width: 100%;
  padding: 0 15px;
`;

const PatientInformation = styled(Flex)`
  padding-right: 25px;
`;

const HeaderContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.lapislazuli};
  padding: 11px;
  overflow: auto hidden;
  flex: 1;
  align-items: flex-start;
  & h3 {
    margin-top: 12px;
  }
`;

const PatientImage = styled.img`
  width: 63px;
  height: 63px;
`;

const PatientImagePlaceHolder = styled(Flex)`
  width: 63px;
  height: 63px;
  max-height: 63px;
  border-radius: 63px;
  background: ${({ theme }) => theme.palette.neosomaOrange};
  color: ${({ theme }) => theme.palette.white};
`;

const Border = styled.div`
  width: 2px;
  background: ${({ theme }) => theme.palette.bluegray};
  height: 55px;
  margin: 0 25px;
`;

const UnderlinedTypography = styled(Typography)`
  text-decoration: underline;
`;

const BackButton = styled.button`
  -webkit-appearance: none;
  background-color: ${({ theme }) => theme.palette.atoll};
  color: ${({ theme }) => theme.palette.white};
  border: 0;
  cursor: pointer;
  transition: 0.5s all;
  &:hover {
    background-color: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.atoll};
  }
`;

const MRN = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 270px;
  text-wrap: nowrap;
`;

const HeaderWrapper = styled(Flex)`
  position: relative;
`;

const PatientActionMenuWrapper = styled.div`
  position: absolute;
  right: 50px;
  bottom: -34px;
`;

function HeaderItem({ title, value, border }) {
  return (
    <>
      <HeaderItemWrapper direction="column" gap="6px">
        <UnderlinedTypography text="white" variant="h3" bold uppercase>
          {title}
        </UnderlinedTypography>
        <Typography text="white" variant="p">
          {value}
        </Typography>
      </HeaderItemWrapper>
      {border && <Border />}
    </>
  );
}

HeaderItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  border: bool
};

HeaderItem.defaultProps = {
  value: null,
  border: false
};

export default function PatientHeader({ patientData }) {
  const { first_name = '', last_name = '', avatar, patient_iuid } = patientData;
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const onCancel = () => {
    setShowUnsavedModal(false);
  };

  const navigateBack = () => {
    if (location?.state?.from) {
      const { from: fullPath } = location.state;
      const url = new URL(fullPath, window.location.origin);
      const to = `${url.pathname}${url.search}`;
      navigate(to, { replace: false });
      return;
    }
    navigate('/', { replace: false });
  };

  const onConfirm = () => {
    sessionStorage.setItem('isUnsaved', false);
    setShowUnsavedModal(false);
    navigateBack();
  };

  return (
    <HeaderWrapper>
      <ConfirmModal
        isShowing={showUnsavedModal}
        onCancel={onCancel}
        onConfirm={onConfirm}
        title="Unsaved Changes"
        message={
          <>
            <div>Are you sure you want to leave this tab?</div>
            <div>You have unsaved changes that will be lost.</div>
          </>
        }
        confirmText="Discard and continue"
      />
      <BackButton
        type="button"
        onClick={(e) => {
          const isUnsaved =
            (sessionStorage.getItem('isUnsaved') || 'false') === 'true';
          if (isUnsaved) {
            e.preventDefault();
            setShowUnsavedModal(true);
            return;
          }
          navigateBack();
        }}
      >
        <FiChevronLeft size="25px" />
      </BackButton>
      <HeaderContainer alignItems="center">
        <PatientInformation alignItems="center" gap="20px">
          {avatar ? (
            <PatientImage
              src={avatar}
              alt={`${maskPII(first_name)} ${maskPII(last_name)}`}
            />
          ) : (
            <PatientImagePlaceHolder
              alignItems="center"
              justifyContent="center"
            >
              <FiUser size="20px" />
            </PatientImagePlaceHolder>
          )}
          <Flex direction="column" gap="4px">
            <Typography
              variant="displayTwo"
              text="white"
              style={{ whiteSpace: 'pre' }}
            >
              <span>{maskPII(last_name)}</span>
              {last_name && first_name ? ', ' : ''}
              <span>{maskPII(first_name)}</span>
            </Typography>
            <MRN text="white" variant="p">
              MRN:{' '}
              <span title={maskPII(patient_iuid)}>{maskPII(patient_iuid)}</span>
            </MRN>
          </Flex>
        </PatientInformation>
        {HEADER_DATA_PATIENT_ITEMS.map((item) => {
          const { title, value, border, formatter = (a) => a } = item;
          if (
            !getFeatureFlag('non-mvp-feature') &&
            ['Attending', 'Referring'].includes(title)
          )
            return null;

          let thisFormatter = () => formatter(patientData[value]);
          if (title === 'Age') {
            thisFormatter = () =>
              formatter(getAge(patientData.dob, patientData.age_type) || '--');
          }
          if (title === 'DOB') {
            thisFormatter = () =>
              patientData.age_type === 0
                ? maskPII(formatter(patientData.dob))
                : '--';
          }
          return (
            <HeaderItem
              key={title}
              title={title}
              value={thisFormatter()}
              border={border}
            />
          );
        })}
      </HeaderContainer>
      <Feature name="non-mvp-feature">
        <PatientActionMenuWrapper>
          <PatientActionMenu />
        </PatientActionMenuWrapper>
      </Feature>
    </HeaderWrapper>
  );
}

PatientHeader.propTypes = {
  patientData: PropTypes.shape({
    id: PropTypes.string,
    patient_iuid: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    sex: PropTypes.string,
    dob: PropTypes.string,
    age_type: PropTypes.number,
    diagnosis: PropTypes.string,
    stage: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    attending: PropTypes.string,
    referring: PropTypes.string,
    avatar: PropTypes.string,
    molecularProfile: PropTypes.string,
    HPI: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    notes: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.string,
    response: PropTypes.string
  }).isRequired
};
