/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { viewerState, selectedPatientState } from '@Utils/atoms';
import getPatientDetailsByStudyId from '@Utils/api/getPatientDetailsByStudyId';

import getString from '@Components/strings';
import { Spinner } from '../../TaskView/TaskListLoader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.platinum};

  & h2 {
    font-size: 4rem;
    font-weight: 100;
    color: ${({ theme }) => theme.palette.shuttlegray};
  }
`;

function findPatientAndStudy(patientList, study_id) {
  const searchKey = 'id';
  let patientId;
  let located_study_id;
  Object.keys(patientList).forEach((key) => {
    if (patientId && located_study_id) return;
    const patient = patientList[key];
    const study = patient?.studies?.find((s) => {
      if (s[searchKey] === study_id) {
        patientId = s.patient_id;
        return true;
      }
      return false;
    });

    if (study) {
      located_study_id = study.id;
      patientId = study.patient_id;
    } else if (patient?.latest_study_id === study_id) {
      patientId = patient.id;
      located_study_id = study_id;
    }
  });
  return [patientId, located_study_id];
}

function ViewerLauncher() {
  const location = useLocation();
  const { study_id } = useParams();
  const [visibleState, setVisible] = useRecoilState(viewerState);
  const [selectedPatient = {}, setSelectedPatient] =
    useRecoilState(selectedPatientState);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const [patient_id, found_study_id] = findPatientAndStudy(
      selectedPatient,
      study_id
    );

    if (
      selectedPatient.currentPatient !== undefined &&
      selectedPatient.currentStudy !== undefined &&
      selectedPatient.currentPatient === patient_id &&
      selectedPatient.currentStudy === found_study_id &&
      !visibleState.visible
    ) {
      // If we are already on the correct patient and study, don't do anything
      if (location.state?.initiator === 'AccessionLauncher') {
        // In that case that accession redirected to the viewer lets add history.
        navigate(`/patients/${patient_id}`, {
          replace: true,
          state: { from: `${window.location.origin}/patients/${patient_id}` }
        });
        setTimeout(() => {
          navigate(`/viewer/${study_id}`, {
            replace: false
          });
        }, 10);
      }
      setVisible({ ...visibleState, visible: true });
    } else if (
      selectedPatient.currentPatient !== undefined &&
      selectedPatient.currentPatient === patient_id &&
      !found_study_id &&
      !visibleState.visible
    ) {
      if (!selectedPatient.currentStudy) {
        setSelectedPatient({
          ...selectedPatient,
          currentPatient: patient_id,
          currentStudy: found_study_id
        });
      }
      setTimeout(() => {
        setVisible({ visible: true });
      }, 1);
    } else if (patient_id && found_study_id && !visibleState.visible) {
      // If we found the patient and study, set the current patient and study
      setSelectedPatient({
        ...selectedPatient,
        currentPatient: patient_id,
        currentStudy: found_study_id
      });
      setTimeout(() => {
        setVisible({ visible: true });
      }, 1);
    } else if (study_id) {
      if (!visibleState.visible) {
        // If we didn't find the patient in cache, fetch patient details with study_id
        setIsLoading(true);
        getPatientDetailsByStudyId(study_id)
          .then((data) => {
            if (typeof data === 'object' && Object.keys(data).length === 0) {
              // No studies found.
              setIsLoading(false);
              navigate('/patient-search', {
                replace: true,
                state: { toastMessage: getString('accessionNotFound') }
              });
              return;
            }
            const patient = { ...data, hasDetails: true };
            setSelectedPatient({
              ...selectedPatient,
              [patient.id]: patient,
              currentPatient: patient.id,
              currentStudy: study_id
            });

            navigate(`/patients/${patient.id}`, {
              replace: true
            });
            setTimeout(() => {
              navigate(`/viewer/${study_id}`, {
                replace: false
              });
            }, 10);

            setIsLoading(false);
            setVisible({ visible: true });
          })
          .catch((error) => {
            setIsLoading(false);
            navigate('/');
          });
      }
    } else {
      // If we didn't find the patient and study, navigate to the home page
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study_id, navigate]);

  return (
    <Container>
      {isLoading && (
        <div className="loading">
          <Spinner
            style={{
              justifyContent: 'center',
              paddingBottom: '100px' // Offset for pagginator
            }}
          >
            <span className="loader" />
          </Spinner>
        </div>
      )}
    </Container>
  );
}

export default ViewerLauncher;
