/* eslint-disable import/no-cycle */
import { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import FocusTrap from 'focus-trap-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { FaPen } from 'react-icons/fa';

import {
  viewerState,
  selectedPatientState,
  userInfoState,
  unsavedState
} from '@Utils/atoms';
import getAuthCookies from '@Utils/api/getAuthCookies';
import getPatientDetail from '@Utils/api/getPatientDetails';
import updateRating from '@Utils/api/updateRating';
import getRating from '@Utils/api/getRating';
import { ENV } from '@Utils/envTools';
import {
  getSlashedDate,
  getScanTimestamp,
  refreshScanTimestamp
} from '@Utils/common';
import {
  Container,
  LeftPane,
  ImageList,
  ImageCard,
  ImageCardLabel,
  CompareLabel,
  EditingArrowContainer,
  BottomContainer,
  CloseButton,
  MinimizeButton
} from './ViewerStyles';
import UnsavedConfirmModal from './UnsavedConfirmModal';
import ScanImage from '../../../patients/Components/ScanImage';
import logoDarkSVG from '../../../assets/logo-dark.svg';

const clone = (obj) => JSON.parse(JSON.stringify(obj));
const sendMessage = (type, data) => {
  document
    ?.getElementById('viewerIframe')
    ?.contentWindow?.postMessage(
      { type, data: data && clone(data) },
      document.location.origin
    );
};

function Viewer() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = useRef(location);
  const initialScroll = useRef(false);
  const [comparing, setComparing] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [{ unsaved: unsavedChanges, blocker }, setUnsavedChanges] =
    useRecoilState(unsavedState);
  const [view, setView] = useRecoilState(viewerState);
  const userInfo = useRecoilValue(userInfoState);
  const [selectedPatient = {}, setSelectedPatient] =
    useRecoilState(selectedPatientState);
  const currentPatient = selectedPatient[selectedPatient.currentPatient] || {};
  // Running the sort directly on the studies object throws a "read-only-property" error
  // So we're running a spread on the array first
  const studyList = [...(currentPatient?.studies || [])].sort(
    (a, b) => new Date(b.study_date) - new Date(a.study_date)
  ); // sort by date Newest to oldest;

  const selectedStudy = selectedPatient.currentStudy;
  const latestStudy = currentPatient.latest_study_id;
  const firstStudy = currentPatient?.studies?.[0]?.id;

  const [studyIdToLoad, setStudyIdToLoad] = useState(
    selectedStudy || latestStudy || firstStudy
  );

  const payload = {
    userInfo,
    hidePII: sessionStorage.getItem('hidePII') === 'true',
    contentUrl: ENV.CONTENT_URL,
    apiEndpoint: ENV.NEO_API,
    patient: currentPatient,
    studyId: undefined
  };

  useEffect(() => {
    const handleWaitForGreenLight = (data) => {
      // We're going to make sure we have updated cookies and post back to do the load
      getAuthCookies().then(() => {
        // We can always send back the updated token info (just in case we got some)
        const tempUser = JSON.parse(localStorage.getItem('user') || '{}');
        const tokenInfo = {
          token_bearer: tempUser.token_bearer,
          token_expires_at: tempUser.token_expires_at
        };
        document
          ?.getElementById('viewerIframe')
          ?.contentWindow?.postMessage(
            { type: 'greenLight', data: { ...data, tokenInfo } },
            document.location.origin
          );
      });
    };

    const handleSegmentationUpdated = () => {
      // TODO: We want to be able to "force" a cookie refresh if < 30 seconds to go
      Promise.all([getPatientDetail(currentPatient.id), getAuthCookies()]).then(
        ([details = {}]) => {
          const { id: patientId } = details;
          const newPatientData = {
            ...selectedPatient,
            [patientId]: {
              ...selectedPatient[patientId],
              ...details,
              id: patientId,
              hasDetails: true
            }
          };
          setSelectedPatient(newPatientData);
        }
      );
    };

    const handleViewerLoaded = () => {
      // TODO: We want to be able to "force" a cookie refresh if < 30 seconds to go
      // In the case where we don't have the patient studies loaded, we need to fetch them
      // And add them to the selectedPatient object
      const getRatingFetch = getRating(studyIdToLoad);
      let patientFetch = Promise.resolve({});
      if (!Array.isArray(currentPatient?.studies)) {
        patientFetch = getPatientDetail(currentPatient.id);
      } else {
        patientFetch = Promise.resolve(currentPatient);
      }
      Promise.all([patientFetch, getRatingFetch, getAuthCookies()]).then(
        ([details = {}, rating]) => {
          const { id: patientId } = details;
          const newPatientData = {
            ...selectedPatient,
            [patientId]: {
              ...selectedPatient[patientId],
              ...details,
              id: patientId,
              hasDetails: true
            }
          };
          setSelectedPatient(newPatientData);
          sendMessage('patientData', {
            ...payload,
            patient: {
              ...currentPatient,
              ...details
            },
            userInfo,
            studyId: studyIdToLoad,
            rating
          });
        }
      );
    };

    const handleViewerMessage = async (event) => {
      const { data } = event;
      const { activeStudy, compareStudy } = data;
      switch (data.type) {
        case 'viewerLoaded':
          handleViewerLoaded();
          break;
        case 'segmentationUpdated':
          // 1: update time stamp
          refreshScanTimestamp();
          handleSegmentationUpdated();
          break;
        case 'waitForGreenLight':
          handleWaitForGreenLight(data);
          break;
        case 'ViewerStateChange':
          setUnsavedChanges(!!data.editState);
          if (activeStudy && compareStudy) {
            if (activeStudy !== studyIdToLoad) {
              setStudyIdToLoad(activeStudy);
              initialScroll.current = false;
            }
            if (compareStudy !== comparing) {
              setComparing(compareStudy);
            }
          }
          if (!compareStudy) {
            setComparing(false);
          }
          break;
        case 'saveRating': {
          const { studyId, id, rating, plane, position, comments } =
            data?.data || {};
          const result = await updateRating({
            id,
            study_id: studyId,
            rating: parseFloat(rating || '0').toFixed(2),
            plane,
            position: `(${position[0] || '0'},${position[1] || '0'},${
              position[2] || '0'
            })`,
            comments
          });

          if (result?.study_rating_id) {
            sendMessage('ratingSaved', {
              id: result.study_rating_id,
              study_id: studyId
            });
          }
          break;
        }
        default:
        // no op
      }
    };
    window.addEventListener('message', handleViewerMessage);
    document.getElementById('root').style.overflow = 'hidden';
    return () => {
      document.getElementById('root').style.overflow = 'auto';
      window.removeEventListener('message', handleViewerMessage);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!initialScroll.current) {
      // scroll to the selected study
      setTimeout(
        () =>
          document
            .getElementById(`viwer-study-card-${studyIdToLoad}`)
            ?.scrollIntoView({
              behavior: 'smooth'
            }),
        100
      );
      initialScroll.current = true;
    }
  }, [studyIdToLoad]);

  // If the user is not logged in, redirect to login
  useEffect(() => {
    if (!userInfo && !userInfo?.id) navigate('/login');
  }, [navigate, userInfo]);

  // If the user changes location, close the viewer.
  useEffect(() => {
    if (!location.pathname.includes('/viewer') && view.visible) {
      setView({ ...view, visible: false });
    }
    if (
      location.pathname.includes('/viewer') &&
      location.pathname !== currentLocation.current.pathname
    ) {
      handleStudyChange(location.pathname.split('/').pop(), false);
    }
  }, [location, setView, view]);

  useEffect(() => {
    const beforeUnload = (event) => {
      // Recommended
      event.preventDefault();

      // Included for legacy support, e.g. Chrome/Edge < 119
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true;
    };
    if (unsavedChanges) {
      window.addEventListener('beforeunload', beforeUnload);
    } else {
      if (blocker?.state === 'blocked' && blocker?.reset) {
        blocker?.reset();
      }
      window.removeEventListener('beforeunload', beforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedChanges]);

  useEffect(() => {
    if (blocker?.state === 'blocked') {
      setShowConfirm(true);
    }
    if (blocker?.state !== 'blocked') {
      setShowConfirm(false);
    }
  }, [blocker?.state]);

  const handleStudyChange = (studyId, trackHistory = true, hotkey = false) => {
    if (hotkey) {
      setComparing(studyId);
    } else if (!comparing) {
      currentLocation.current.pathname = `/viewer/${studyId}`;
      if (trackHistory) {
        navigate(`/viewer/${studyId}`, {
          state: {
            ...(location?.state || {}),
            index: location.state?.index ? location.state.index + 1 : 1,
            initiator: 'viewer'
          }
        });
      }
    }
    sendMessage('startProgressBar');
    // In the case where we don't have the patient studies loaded, we need to fetch them
    // And add them to the selectedPatient object
    let patientFetch = Promise.resolve({});
    if (!Array.isArray(currentPatient?.studies)) {
      patientFetch = getPatientDetail(currentPatient.id);
    } else {
      patientFetch = Promise.resolve(currentPatient);
    }

    const getRatingFetch = getRating(studyId);

    Promise.all([patientFetch, getRatingFetch, getAuthCookies()]).then(
      ([details, rating = []]) => {
        const { id: patientId } = details;
        const newPatientData = {
          ...selectedPatient,
          [patientId]: {
            ...selectedPatient[patientId],
            ...details,
            id: patientId,
            hasDetails: true
          }
        };
        setSelectedPatient(newPatientData);
        sendMessage('patientData', {
          ...payload,
          patient: {
            ...currentPatient,
            ...details
          },
          userInfo,
          studyId,
          rating,
          hotkey
        });
      }
    );
    if (!comparing && !hotkey) {
      setStudyIdToLoad(studyId);
    }
  };

  const handleConfirmUnsaved = () => {
    setUnsavedChanges(false);
    if (blocker?.state === 'blocked' && blocker?.proceed) {
      blocker?.proceed();
    }
    setView({ ...view, visible: false });
  };

  const handleCancelUnsaved = () => {
    if (blocker?.state === 'blocked' && blocker?.reset) {
      blocker?.reset();
    }
    setShowConfirm(false);
  };

  const handleCloseViewer = () => {
    if (unsavedChanges) {
      setShowConfirm(true);
    } else {
      if (location.state?.from) {
        const { from: fullPath } = location.state;
        const url = new URL(fullPath, window.location.origin);
        const to = `${url.pathname}${url.search}`;
        navigate(to, { replace: false });
        return;
      }
      if (location.state?.index) {
        navigate((location.state.index + 1) * -1);
      } else {
        navigate(-1);
      }
      setView({ ...view, visible: false });
    }
  };

  const getPatientImage = (studyId, sequence) => {
    // Bust the cache for any "edited" images after any edit
    const ts = getScanTimestamp();
    let filename = `${currentPatient.hospital_id}/${currentPatient.id}/${studyId}/`;
    filename += `p${filename.replaceAll('/', '_')}preview`;
    return `${filename}_${sequence}.png?ts=${ts}`;
  };
  const tsPng = `.png?ts=${getScanTimestamp()}`;

  return (
    <FocusTrap focusTrapOptions={{ initialFocus: false }}>
      <Container>
        {showConfirm && (
          <UnsavedConfirmModal
            isShowing={showConfirm}
            onPrimayClick={handleConfirmUnsaved}
            onCancleClick={handleCancelUnsaved}
          />
        )}
        <LeftPane>
          <ReactSVG className="logo" src={logoDarkSVG} />
          <MinimizeButton disabled>
            {unsavedChanges && (
              <EditingArrowContainer>
                <FaPen />
                Edit in progress
              </EditingArrowContainer>
            )}
          </MinimizeButton>
          <ImageList>
            {studyList
              .filter((s) => s.status === 10)
              .map((study) => {
                const isSelected =
                  studyIdToLoad === study.id ? true : undefined;
                const isRight = study.id === comparing ? true : undefined;
                const isBoth = studyIdToLoad === comparing ? true : undefined;
                const handleClicked = (e) => {
                  if (!unsavedChanges && !isSelected && e.detail === 2) {
                    if (e.metaKey || e.ctrlKey) {
                      handleStudyChange(study.id, false, true);
                      sendMessage('ratingHide');
                    } else {
                      handleStudyChange(study.id);
                    }
                  }
                };
                const isDisabled = unsavedChanges && !isSelected && !isRight;
                return (
                  <ImageCard
                    key={study.id}
                    id={`viwer-study-card-${study.id}`}
                    selected={isSelected || isRight}
                    title={
                      // eslint-disable-next-line no-nested-ternary
                      !isSelected && !isRight
                        ? !unsavedChanges
                          ? 'Double click to view this study'
                          : ''
                        : ''
                    }
                    onClick={handleClicked}
                    disabled={isDisabled}
                  >
                    {isRight && !isBoth && <CompareLabel>Right</CompareLabel>}
                    {comparing && isSelected && !isBoth && (
                      <CompareLabel left>Left</CompareLabel>
                    )}
                    {comparing && isSelected && isBoth && (
                      <CompareLabel left>Dual</CompareLabel>
                    )}
                    <ImageCardLabel>
                      {getSlashedDate(study.study_date)}
                    </ImageCardLabel>
                    <ScanImage
                      hospitalId={currentPatient.hospital_id}
                      image={(study?.preview_image || '').replace(
                        '.png',
                        tsPng
                      )}
                      title={
                        // eslint-disable-next-line no-nested-ternary
                        !isSelected && !isRight
                          ? unsavedChanges
                            ? 'Please save or cancel changes before switching studies'
                            : 'Double click to view this study'
                          : unsavedChanges
                          ? 'Please save or cancel changes to switch studies'
                          : ''
                      }
                      overlay={getPatientImage(study.id, 'seg')}
                      showInteractiveLabel
                      showSpinner
                      disableViewerDblClick
                      showPointer={!isSelected && !isRight}
                      style={{ height: '151px', width: '126px' }}
                      disabled={isDisabled}
                    />
                  </ImageCard>
                );
              })}
          </ImageList>
          <BottomContainer>
            <div />
            <CloseButton variant="secondary" onClick={handleCloseViewer}>
              Close
            </CloseButton>
          </BottomContainer>
        </LeftPane>
        <iframe
          id="viewerIframe"
          src="/viewer/index.html"
          title="Neosoma Viewer"
        />
      </Container>
    </FocusTrap>
  );
}

export default function Show(...props) {
  // Show is a component that will only render if the viewerState is visible.
  // This will prevent the viewer from being rendered when it is not needed.
  // This is so that the viewer will not pass data to the iframe when it is available.
  const view = useRecoilValue(viewerState);
  return view.visible ? <Viewer {...props} /> : null;
}
