// SonarQube thinks these are IP Addresses (they're NOT :)
export const VERSION = '3.10.0.0'; // NOSONAR
export const HGG_VERSION = '2.4.0.0'; // NOSONAR

export const ENV = {
  get NEO_API() {
    return getEnv('neo_api');
  },
  get CLIENT_ID() {
    return getEnv('client_id');
  },
  get CONTENT_URL() {
    return getEnv('content_url') || window.location.origin;
  },
  get CONTENT_HOST() {
    return ENV.CONTENT_URL.split('/').pop();
  },
  get DOMAIN_TITLE() {
    return (
      getEnv('domain_title') ||
      initCap(getSubdomain().replace(/[^a-zØ-öø-ÿ]/g, ' '))
    );
  },
  get BRAND_LOGO_IMAGE() {
    return getEnv('brand_logo_image');
  },
  get OAUTH2() {
    const oauth2 = getEnv('oauth2');
    if (oauth2 === undefined) return undefined;
    if (oauth2.connections === undefined) {
      oauth2.connections = ['Username-Password-Authentication'];
    }
    return oauth2;
  }
};

const getEnv = (key) => {
  if (!window.env || window.env[key] === undefined) return undefined;
  return window.env[key];
};

const initCap = (s) => {
  if (typeof s !== 'string') return '';
  return s
    .toLowerCase()
    .replace(/(?:^|[^a-zØ-öø-ÿ])[a-zØ-öø-ÿ]/g, (m) => m.toUpperCase());
};

const getSubdomain = () => {
  let domain = window.location.hostname;
  if (window.location.hostname.includes('://')) {
    [, domain] = window.location.hostname.split('://');
  }
  const subdomain = domain.split('.')[0];
  return subdomain;
};

export default getEnv;
