import { useRef, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { idleTimeoutState, userInfoState } from '@Utils/atoms';
import { useNavigate } from 'react-router-dom';
import getString from '@Components/strings';
import { getSetting } from '@Utils/userSettings';

function IdleTimeout() {
  const navigate = useNavigate();
  const intervalId = useRef(null);
  const idleTimeout = useRef(0);
  const [idleTimeoutActive, setidleTimeoutActive] =
    useRecoilState(idleTimeoutState);
  const userInfo = useRecoilValue(userInfoState);

  // On mount and idleTimeoutActive change.
  useEffect(() => {
    function timerIncrement() {
      idleTimeout.current += 1;
      const userIsidle = idleTimeout.current > 19;
      if (userIsidle) {
        idleTimeout.current = 0;
        navigate('/login', {
          replace: true,
          state: {
            authMessage: getString('sessionTimeoutMessage')
          }
        });
      }
    }
    if (!idleTimeoutActive) {
      clearInterval(intervalId.current);
      return () => {};
    }
    intervalId.current = setInterval(timerIncrement, 60000); // 1 minute
    idleTimeout.current = 0;
    return () => {
      clearInterval(intervalId.current);
    };
  }, [idleTimeoutActive, navigate]);

  // On mount
  useEffect(() => {
    if (userInfo?.id) {
      const rememberMe =
        getSetting(userInfo.id, 'rememberMe') === undefined
          ? true
          : getSetting(userInfo.id, 'rememberMe');
      if (!rememberMe && !idleTimeoutActive) {
        setidleTimeoutActive('start');
      }
    }

    function timerReset() {
      idleTimeout.current = 0;
    }

    window.addEventListener('mousemove', timerReset);
    window.addEventListener('keypress', timerReset);
    return () => {
      window.removeEventListener('mousemove', timerReset);
      window.removeEventListener('keypress', timerReset);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}

export default IdleTimeout;
