import { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {
  Flex,
  ConfirmModal,
  DropDown,
  DropdownItem
} from '@Components/NeosomaUI';
import warmUpExport from '@Utils/api/warmUpExport';
import postStudyExportRequest from '@Utils/api/postStudyExportRequest';
import { pingExport, downloadExport } from '@Utils/api/pingExport';

const ExportDropDown = styled(DropDown)`
  color: ${({ theme }) => theme.palette.mineshaft};
  background: ${({ theme }) => theme.palette.white};
  width: 191px;
  height: 34px;
  justify-content: space-between;
  border-color: ${({ theme }) => theme.palette.lightsilver};
  border-radius: 2px;
`;

const Container = styled.div`
  display: grid;
  gap: 28px;
  margin-top: 28px;
  margin-bottom: 32px;
`;

const ProgressBar = styled.div`
  height: 10px;
  background-color: ${({ theme }) => theme.palette.pacific};
  width: ${({ percent }) => percent}%;
  transition: width 0.3s;
  border-radius: 5px;
`;

const DropDownContainer = styled.div`
  display: flex;
  width: 313px;
  & div {
    flex: 1;
  }
  & .label {
    font-weight: 600;
    align-items: center;
    display: flex;
  }
  nav {
    top: 37px;
    min-width: 190px;
    & p[type='button'] {
      padding: 5px;
    }
  }
`;

const ErrorContainer = styled(Flex)`
  color: ${({ theme }) => theme.palette.thunderbird};
  justify-content: center;
  font-weight: 600;
`;

const exportTypeList = [
  { value: 1, name: 'All Labels' },
  { value: 2, name: 'Separate Lesions (Enh)' }
];

const referenceSeriesList = [
  { value: 1, name: 'T1 Post' },
  { value: 2, name: 'T2 FLAIR' },
  { value: 3, name: 'T1 Pre' },
  { value: 4, name: 'T2 Weighted' }
];

const platformIdList = [
  { value: 1, name: 'Eclipse' },
  { value: 1.1, name: 'MIM' },
  { value: 2, name: 'Stealth' },
  { value: 3, name: 'Brainlab' }
];
const warningLabel = 'Warning:';
const exportErrorStatus = 'Unexpected Error - see log: 0';

function ExportModal({
  useShowExport,
  patientId,
  studyId,
  studyDate,
  volumes
}) {
  const [showExport, setShowExport] = useShowExport();
  const [platformId, setPlatformId] = useState('');
  const [exportType, setExportType] = useState('');
  const [referenceSeries, setReferenceSeries] = useState('');
  const [disableButtons, setDisableButtons] = useState(false);
  const [disablePrimary, setDisablePrimary] = useState(false);
  const [error, setError] = useState(false);
  const [percent, setPercent] = useState(0);
  const [status, setStatus] = useState('');
  const errorRef = useRef(error);
  const exportLoopCont = useRef(0);
  const nextIncr = useRef(1);
  const exportS3Data = useRef();
  const exportStatus = useRef('');
  const nextPercent = useRef(0);
  const volumesRef = useRef(volumes);
  const studyDateRef = useRef(studyDate);

  const resetTrackingValues = () => {
    setPercent(0);
    setStatus('');
    setError(false);
    nextIncr.current = 1;
    exportLoopCont.current = 0;
    exportS3Data.current = null;
    exportStatus.current = '';
    nextPercent.current = 0;
  };

  const resetModalButtons = () => {
    setDisableButtons(false);
    setDisablePrimary(false);
  };

  const handleConfirmUnsaved = async (ignoreError = false) => {
    if (ignoreError) {
      setError(false);
      errorRef.current = false;
    } else if (errorRef.current) {
      resetModalButtons();
      return;
    }
    setDisableButtons(true);
    if (exportLoopCont.current === 0) {
      // on the first loop, we need to make an export request and save the data
      const compress = 0;
      setStatus('Contacting Server...');
      exportS3Data.current = await postStudyExportRequest({
        patientId,
        studyId,
        platformId: Math.floor(platformId.value),
        exportType: exportType.value,
        seriesTypeIdx: referenceSeries.value,
        compress
      });
    }
    const data = exportS3Data.current;
    if (!data || !data.file_name) {
      setError(true);
      return;
    }
    nextPercent.current += nextIncr.current;
    if (exportLoopCont.current === 0 || exportLoopCont.current % 5 === 0) {
      exportStatus.current =
        (await pingExport(data.file_name)) || 'Initializing: 0';
      const [, resPercent] = exportStatus.current.split(': ');
      const resPctVal = parseInt(resPercent, 10);
      if (resPctVal > nextPercent.current) {
        setPercent(resPctVal);
        nextPercent.current = resPctVal;
      }
    }
    // We don't want the progress bar to hit 100% before timing out.
    // The "Initializing" stage could be almost instantanious, which coulc conceivably
    // move the timer very quickly to 30 or even 50%. Worst case we move immediately to 50 and then:
    // 12 seconds from 50 to 75 %
    // 15 seconds between 75 and 90 %
    // 10 seconds between 90 and 95 %
    // 20 seconds between 95 and 100 %
    if (nextPercent.current >= 95) {
      // Give ourselves another 40 ticks (20 seconds) to finish
      nextIncr.current = 0.125;
    } else if (nextPercent.current >= 90) {
      // Slow the increment down to give us 20 ticks (10 seconds) to the next change
      nextIncr.current = 0.25;
    } else if (nextPercent.current >= 75) {
      // We've hit 75%, so slow the count down to 1% per second
      nextIncr.current = 0.5;
    } else if (nextPercent.current >= 100) {
      // This shouldn't happen, but just in case someone tweaks the timing above
      nextPercent.current = 90;
    }
    setStatus(exportStatus.current.split(': ')[0]);
    setPercent(Math.round(nextPercent.current));
    exportLoopCont.current += 1;
    const response = exportStatus.current;

    // Breakout of the loop if we get an error status or we have looped for > 55 seconds
    const OK =
      response !== exportErrorStatus && exportLoopCont.current < 55 * 2;
    if (OK) {
      if (nextPercent.current === 100) {
        exportS3Data.current = null;
        exportLoopCont.current = 0;
        setTimeout(handleDownload, 1000, data);
      } else {
        setPercent(Math.round(nextPercent.current));
        setTimeout(handleConfirmUnsaved, 500);
      }
    } else {
      setError(true);
    }
  };

  const handleDownload = async (data) => {
    const blob = await downloadExport(data.file_name);
    if (blob instanceof Error) {
      setError(true);
      return;
    }
    const downloadLink = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    downloadLink.href = url;
    const platform = platformIdList.find((p) => p.value === platformId.value);
    const shortName = platform.name.split(' ')[0];
    const dateVal = studyDateRef.current.replace(/-/g, '_');
    const fileName = `${studyId}_${shortName}_${dateVal}.zip`;
    downloadLink.download = fileName;
    downloadLink.click();
    // Clean up
    // document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);

    setTimeout(() => {
      setDisableButtons(false);
      setShowExport(false);
      resetTrackingValues();
    }, 500);
  };

  const handleCancel = () => {
    setShowExport(false);
  };

  useEffect(() => {
    // Let's do a quick validation:
    let warning = '';
    if (exportType.value === 2) {
      const enhVol = volumesRef.current?.find(
        (vol) => vol.label === 'Enhancing'
      );
      if (!enhVol || enhVol.volume === 0) {
        warning = `${warningLabel} There are no enhancing lesions to export!`;
      }
    } else {
      const allVol = volumesRef.current?.reduce((s, v) => s + v.volume, 0);
      if (!allVol || allVol === 0) {
        warning = `${warningLabel} There are no lesions to export!`;
      }
    }
    if (Math.floor(platformId.value) === 2 && exportType.value === 2) {
      warning = `${warningLabel} Stealth Export does not support Separate Lesions`;
    } else if (Math.floor(platformId.value) === 3 && exportType.value === 2) {
      warning = `${warningLabel} Brainlab Export does not support Separate Lesions`;
    }
    // Turn on the warning if we have one
    if (warning) {
      resetTrackingValues();
      setStatus(warning);
    } else if (status.startsWith(warningLabel)) {
      // Try not to clear the status unless it was another warning
      setStatus('');
    }
    if (platformId && exportType && referenceSeries && status === '') {
      setDisablePrimary(false);
    } else {
      setDisablePrimary(true);
    }
  }, [platformId, exportType, referenceSeries, status]);

  useEffect(() => {
    if (!showExport) return;
    warmUpExport(patientId, studyId);
    setPercent(0);
    if (exportType === '') {
      // Set the default Export Type if not set yet
      setExportType(exportTypeList[0]);
    }
    if (referenceSeries === '') {
      // Same for the referenceSeries
      setReferenceSeries(referenceSeriesList[0]);
    }
    // Let's reset the platformId to '' when the modal is opened
    setPlatformId('');
    // And clear out any prior errors/status messages
    setStatus('');
    setError(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExport]);

  useEffect(() => {
    errorRef.current = error;
    if (error) {
      resetModalButtons();
      exportStatus.current = '';
    }
  }, [error]);

  return (
    <ConfirmModal
      isShowing={showExport}
      onConfirm={() => {
        setError(false);
        resetTrackingValues();
        handleConfirmUnsaved(true);
      }}
      onCancel={handleCancel}
      title="Export Study"
      disableButtons={disableButtons}
      disablePrimary={disablePrimary}
      message={
        <div>
          Select the Export Platform, Type and Reference Series to accompany the
          export
          <Container>
            <DropDownContainer>
              <div className="label">Target Platform:</div>
              <ExportDropDown
                id="target-platform"
                variant="slim"
                hideOnSelect
                buttonText={platformId.name || 'Select target platform'}
              >
                {platformIdList.map((item) => (
                  <DropdownItem
                    key={`items-per-page-${item.name.split(' ').join('-')}`}
                    onClick={() => setPlatformId(item)}
                  >
                    {item.name}
                  </DropdownItem>
                ))}
              </ExportDropDown>
            </DropDownContainer>
            <DropDownContainer>
              <div className="label">Export Type:</div>
              <ExportDropDown
                id="export-type"
                variant="slim_transpar"
                hideOnSelect
                buttonText={exportType.name || 'Select Export Type'}
              >
                {exportTypeList.map((item) => (
                  <DropdownItem
                    key={`items-per-page-${item.name.split(' ').join('-')}`}
                    onClick={() => setExportType(item)}
                  >
                    {item.name}
                  </DropdownItem>
                ))}
              </ExportDropDown>
            </DropDownContainer>
            <DropDownContainer>
              <div className="label">Reference Series:</div>
              <ExportDropDown
                id="reference-series"
                variant="slim"
                hideOnSelect
                buttonText={referenceSeries.name || 'Select Reference Series'}
              >
                {referenceSeriesList.map((item) => (
                  <DropdownItem
                    key={`items-per-page-${item.name.split(' ').join('-')}`}
                    onClick={() => setReferenceSeries(item)}
                  >
                    {item.name}
                  </DropdownItem>
                ))}
              </ExportDropDown>
            </DropDownContainer>
          </Container>
          {!error && (
            <div>
              {status && status.startsWith(warningLabel) && (
                <> {status.substring(warningLabel.length)} </>
              )}
              {status && !status.startsWith(warningLabel) && (
                <>
                  {status.split(': ')[0]} {percent}%
                </>
              )}
              <ProgressBar percent={percent} />
            </div>
          )}
          {error && (
            <ErrorContainer>
              There was an error exporting the study.
              <br />
              Please try again or contact Neosoma Support
            </ErrorContainer>
          )}
        </div>
      }
      confirmText="Export"
      style={{ width: '490px' }}
    />
  );
}

ExportModal.propTypes = {
  patientId: PropTypes.string.isRequired,
  studyId: PropTypes.string.isRequired,
  studyDate: PropTypes.string.isRequired,
  volumes: PropTypes.instanceOf(Array).isRequired,
  useShowExport: PropTypes.func.isRequired
};

export default ExportModal;
