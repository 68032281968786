import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { ENV } from '@Utils/envTools';
import getString from '@Components/strings';
import { Button, Modal } from '@Components/NeosomaUI';

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 30px;
  padding-bottom: 35px;
`;

function VersionInfoCard({ showReleaseNotes }) {
  const [showModal, setShowModal] = useState(false);
  const configLoaded = ENV.CLIENT_ID !== undefined;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'Enter') {
        const selection = window.getSelection();
        const selectedText = selection.toString();
        if (selectedText === 'Neosoma Dashboard') {
          window.localStorage.neosomaAdmin = !(
            window.localStorage.neosomaAdmin === 'true'
          );
        }
      }
    };

    if (showModal) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showModal]);

  return (
    <>
      <Modal
        title="Neosoma Dashboard"
        isShowing={showModal}
        hideFooter
        hide={() => setShowModal(false)}
        style={{ width: '350px' }}
      >
        <ModalContentContainer>
          <strong>Current Version Info:</strong>
          <div>
            <div>{getString('dashboardVersionText')}</div>
            <div>{getString('dashboardGUIDText')}</div>
          </div>
          <div>
            <div>{getString('hggVersionText')}</div>
            <div>{getString('hggGUIDText')}</div>
          </div>
        </ModalContentContainer>
      </Modal>
      <div>
        <div>{getString('dashboardVersionText')}</div>
        <div>{getString('dashboardGUIDText')}</div>
        <Button
          className="info-button"
          type="button"
          variant="link"
          onClick={(event) => setShowModal(true)}
          disabled={!configLoaded}
        >
          Additional Version Info
        </Button>
      </div>
    </>
  );
}

VersionInfoCard.propTypes = {
  showReleaseNotes: PropTypes.bool
};

VersionInfoCard.defaultProps = {
  showReleaseNotes: false
};

export default VersionInfoCard;
