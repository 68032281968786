import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { useNavigate, useLocation } from 'react-router-dom';
import Auth0Lock from 'auth0-lock';

import { ENV } from '@Utils/envTools';
import VersionInfoCard from '@Components/UserSettings/VersionInfoCard';
import { Flex, Typography } from '@Components/NeosomaUI';

import { getCookieValue } from '@Utils/common';
import { ONE_TIME_ERROR_CODES } from '@Utils/generalConstants';

import LoginForm from './LoginForm';
import BrandingImg from './BrandingImg';

const GuidTextContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
  & .info-button {
    margin-top: 10px;
    height: 26px;
  }
`;

const shouldForwardProp = (prop) => isPropValid(prop) && prop !== 'hasLogo';

const Container = styled(Flex)`
  color: ${({ theme }) => theme.palette.white};
  min-width: 100%;
  background: linear-gradient(0deg, rgba(8, 27, 58, 0.8), rgba(8, 27, 58, 0.8)),
    url(/login-bg.png);
  background-size: cover;
  background-position: -366px top;
  height: 100vh;

  & h1 {
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
  }
`;

const ContainerLeft = styled(Flex, shouldForwardProp)`
  padding: 33px;
  font-size: 18px;
  line-height: 22px;
  & > div:last-child {
    margin-bottom: 56px;
  }
  & img {
    aspect-ratio: 21829 / 5300;
    min-width: 218px;
    width: 32%;
  }
`;

const ContainerRight = styled(Flex, { shouldForwardProp })`
  color: ${({ theme }) => theme.palette.mineshaft};
  background-color: ${({ theme }) => theme.palette.athensgray};
  padding: ${({ auth0 }) => (auth0 ? '0 80px' : '0 117px')};
  max-width: 550px;
  min-width: ${({ auth0 }) => (auth0 ? '550px' : 'initial')};

  padding-top: ${({ hasLogo }) => (hasLogo ? '80px' : '150px')};
  overflow: auto;

  & h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;
    margin-bottom: ${({ hasLogo }) => (hasLogo ? '0' : '56px')};
  }
  /* stlye the auth0 lock */
  & .auth0-lock.auth0-lock .auth0-lock-center {
    padding: 0;
    font-family: ${({ theme }) => theme.fontFamily.display};
  }

  & .auth0-lock.auth0-lock.auth0-lock-opened-in-frame .auth0-lock-widget {
    margin: 0;
  }

  & .auth0-lock.auth0-lock .auth0-lock-widget {
    width: 100%;
  }

  & .auth0-lock.auth0-lock button.auth0-lock-submit {
    background-color: ${({ theme }) => theme.palette.tangerine} !important;
  }
  & .auth0-lock-form {
    padding-top: 32px !important;
  }

  & .auth0-lock-input-block {
    margin-bottom: 37px !important;
  }
  .auth0-lock.auth0-lock .auth0-lock-input-wrap {
    border-color: ${({ theme }) => theme.palette.submarine};
  }

  & .auth0-lock.auth0-lock .auth0-global-message span {
    white-space: pre-wrap;
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.azure};
  text-decoration: none;
  line-height: 19px;
  &:hover {
    text-decoration: underline;
  }
`;

// Some variables for our observer / sign up error handling
let errorObserver;
let auth0FriendlyMessage;

function validateSigninForm(domains) {
  const emailInput = document.querySelectorAll("input[type='email']")[0];
  const invalidHint = 'Please use your corporate email to login';
  if (emailInput && typeof emailInput.onchange !== 'function') {
    const containerDiv = document.querySelector('.auth0-lock-input-email');
    emailInput.onchange = () => {
      // Start by removing any existing custom Neosoma Error:
      const lastMsg = containerDiv.querySelector('.neo-custom-email-msg');
      if (lastMsg) {
        lastMsg.parentElement.removeChild(lastMsg);
      }
      // If auth0 is showing a message we'll need to hide that (if we're going to show our own)
      const existingMsg = containerDiv.querySelector('.auth0-lock-error-msg');
      const val = emailInput.value;
      const isInvalid = val && !domains.includes(val.split('@').pop());
      if (isInvalid) {
        if (existingMsg) {
          // Hide this because removing it will cause an error later
          existingMsg.style.display = 'none';
        }
        const errorDiv = document.createElement('div');
        errorDiv.role = 'alert';
        errorDiv.id = 'auth0-lock-error-message-email';
        errorDiv.classList.add('auth0-lock-error-msg', 'neo-custom-email-msg');
        const hintDiv = document.createElement('div');
        hintDiv.classList.add('auth0-lock-error-invalid-hint');
        hintDiv.textContent = invalidHint;
        errorDiv.appendChild(hintDiv);
        containerDiv.classList.add('auth0-lock-error');
        containerDiv.appendChild(errorDiv);
      } else if (existingMsg && existingMsg.style.display === 'none') {
        // Turn their default message back on if our prior keystrokes didn't clear it already
        existingMsg.style.display = '';
      }
    };
  }
  emailInput.dispatchEvent(new Event('change'));
}

// We need a handler to catch the "generic" error message coming from our "Action"
// and replace it with the friendly message in that same payload
function signupErrorHandler(error) {
  if (error.code === 'extensibility_error') {
    // Let's wire up an observer waiting for the Error Message node to populate
    auth0FriendlyMessage = error?.original?.response?.body?.friendly_message;

    if (errorObserver) {
      // We're already observing this error display area
      return;
    }

    // Select the node that will be observed for mutations
    const targetNode = document.getElementsByClassName(
      'auth0-lock-content-body-wrapper'
    )[0];

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = (mutationsList, observer) => {
      const errorDiv = document.getElementsByClassName(
        'auth0-global-message auth0-global-message-error'
      )[0];
      if (auth0FriendlyMessage && errorDiv) {
        const spans = errorDiv.querySelectorAll('span');
        // The span we want *should be* the last one, but we'll iterate to find it
        spans.forEach((item) => {
          if (item.innerHTML.startsWith("We're sorry")) {
            const itemForUpdate = item;
            itemForUpdate.textContent = auth0FriendlyMessage;
            auth0FriendlyMessage = null;
          }
        });
      }
    };

    // Create an observer instance linked to the callback function
    errorObserver = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    errorObserver.observe(targetNode, config);
  }
}

function LoginView({ type }) {
  const [signUpExpired, setSignUpExpired] = useState(undefined);
  const configLoaded = ENV.CLIENT_ID !== undefined;
  const auth0Settings = ENV.OAUTH2;
  const isAuth0 = auth0Settings.organization !== undefined;
  const location = useLocation();
  const navigate = useNavigate();

  const logOutInFrame = (url) => {
    // Create a new iframe element
    const iframe = document.createElement('iframe');

    // Define a function to remove the iframe once the page is loaded
    iframe.onload = () => {
      // Remove the iframe from the body
      document.body.removeChild(iframe);
    };

    // Set the iframe's style to make it hidden
    iframe.style.display = 'none';

    // Set the iframe's source to the provided URL
    iframe.src = url;

    // Append the iframe to the body
    document.body.appendChild(iframe);
  };

  useEffect(() => {
    if (isAuth0) {
      if (localStorage.getItem('user')) {
        logOutInFrame(`https://${auth0Settings.domain}/v2/logout`);
        localStorage.removeItem('user');
      }

      let allowGoogleLogin = false; // Neosoma Employees can set the localStorage "neosomaAdmin" flag to use their Google login

      const allowedConnections = auth0Settings.connections;

      // Set a special flag for SSO-only signups
      const ssoOnly =
        type === 'signup' &&
        allowedConnections.length === 1 &&
        allowedConnections[0] !== 'Username-Password-Authentication';

      if (window.localStorage.neosomaAdmin === 'true') {
        allowGoogleLogin = true;
        allowedConnections.push('google-oauth2');
      }
      let invitationToken;
      if (type === 'signup') {
        // Get the invitation token from the URL - otherwise we're turning off sign ups
        const [inviToken, creatTime] = (
          sessionStorage.getItem('auth0Invitation') || '|'
        ).split('|');
        if (inviToken && creatTime && Date.now() - creatTime < 20 * 60 * 1000) {
          invitationToken = inviToken;
          setSignUpExpired(false);
          // For some reason, auth0 makes us add this when we're doing an SSO-only sign up
          if (ssoOnly) {
            allowedConnections.push('Username-Password-Authentication');
          }
        } else {
          // Clear invitation token from session storage if it's expired
          sessionStorage.removeItem('auth0Invitation');
          setSignUpExpired(true);
        }
      } else {
        // Clear invitation token from session storage if on login page
        sessionStorage.removeItem('auth0Invitation');
      }
      // Customize Lock configuration based on presence of invitation token
      const lockConfig = {
        allowSignUp: !!invitationToken, // Enable sign-up only if invitation token is present
        allowLogin: !invitationToken, // Disable login (only sign-up)
        allowSocial: allowGoogleLogin, // Allow this to be enabled for Neosoma Employees
        allowedConnections,
        configurationBaseUrl: `https://${auth0Settings.domain}`,
        container: 'lock-container',
        languageDictionary: {
          title: 'Log in to Neosoma',
          error: {
            login: {
              'lock.invalid_email_password':
                'Access Denied - Invalid Username / Password'
            }
          }
        },
        theme: {
          logo: `${window.location.origin}/configs/images/${
            window.location.host.split('.')[0]
          }.png`
        },
        auth: {
          sso: false,
          redirectUrl: `${window.location.origin}/auth/callback`,
          responseType: 'token',
          audience: auth0Settings.audience,
          params: {
            organization: auth0Settings.organization,
            organization_name: auth0Settings.organization_name,
            scope: 'openid profile email offline_access'
          }
        },
        additionalSignUpFields: [
          {
            name: 'given_name',
            placeholder: 'Enter your first name',
            validator(value) {
              return {
                valid: value.length >= 1,
                hint: 'First name cannot be empty'
              };
            }
          },
          {
            name: 'family_name',
            placeholder: 'Enter your last name',
            validator(value) {
              return {
                valid: value.length >= 1,
                hint: 'Last name cannot be empty'
              };
            }
          }
        ]
      };

      // Remove the first and last name fields if we're only allowing SSO
      if (ssoOnly) {
        lockConfig.additionalSignUpFields = [];
      }

      if (invitationToken) {
        lockConfig.auth.params.invitation = invitationToken;
        // We could also add in a hidden field for the sign up form:
        lockConfig.additionalSignUpFields.push({
          name: 'auth0_invitation',
          type: 'hidden',
          value: invitationToken
        });

        lockConfig.additionalSignUpFields.push({
          name: 'auth0_organization',
          type: 'hidden',
          value: auth0Settings.organization
        });
      }

      // eslint-disable-next-line no-unused-vars
      const lock = new Auth0Lock(
        auth0Settings.client_id,
        auth0Settings.domain,
        lockConfig
      );

      let options = {};
      if (location.state?.authMessage) {
        options = {
          flashMessage: { type: 'error', text: location.state?.authMessage }
        };
      } else if (getCookieValue('oneTimeError')) {
        const errorCode = getCookieValue('oneTimeError');
        options = {
          flashMessage: {
            type: 'error',
            text: ONE_TIME_ERROR_CODES[errorCode]
          }
        };
        const { hostname } = window.location;
        const domain = hostname.split('.').slice(-2).join('.');
        document.cookie = `oneTimeError=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain}; path=/`;
      }

      setTimeout(() => {
        lock.show(options);
        lock.on('signup error', signupErrorHandler);

        if (auth0Settings.sso_domains) {
          // We need to validate the email domain for SSO users
          lock.on(
            'signup submit',
            validateSigninForm.bind(null, auth0Settings.sso_domains)
          );
          if (ssoOnly) {
            // SSO is our only option, so hide the password field from the user
            lock.on('signup ready', () => {
              const pwdInput = document.querySelector(
                '.auth0-lock-input-show-password'
              );
              if (pwdInput) {
                pwdInput.style.display = 'none';
              }
            });
          }
        }
      }, 50);
    }
    // Only set this value if we're on a valid Auth0 domain
    if (auth0Settings.organization) {
      const { hostname } = window.location;
      const domain = hostname.split('.').slice(-2).join('.');
      document.cookie = `lastLogin=${hostname}; domain=.${domain}; path=/`;
    }
    // clear location state
    if (location.state?.authMessage) {
      navigate(null, { replace: true, state: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signUpExpired) {
      navigate('/login', {
        replace: true,
        state: {
          authMessage:
            'The invitation link has expired.\nPlease try the link in your email again or contact support.'
        }
      });
      setTimeout(() => {
        // Needed to reload the page for the error message to show up.
        // since we re-use the component the user effect doesn't re-run.
        window.location.reload();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpExpired]);

  return (
    <Container>
      <ContainerLeft
        alignItems="center"
        grow="1"
        direction="column"
        justifyContent="space-between"
      >
        <img src="/login-logo.png" alt="Neosoma" />
        <Flex direction="column" gap="18px">
          <Typography variant="displayOne" text="white">
            Welcome to Neosoma
          </Typography>
          <Typography variant="displayTwo" text="white">
            Advancing Brain Tumor Clinical Care and Clinical Trials
          </Typography>
        </Flex>
      </ContainerLeft>
      <ContainerRight
        grow="0"
        hasLogo={configLoaded}
        direction="column"
        auth0={`${isAuth0}`}
      >
        {isAuth0 && (
          <GuidTextContainer>
            <div id="lock-container" />
            {type === 'signup' && (
              <StyledLink href="/login" rel="noreferrer">
                Return to login
              </StyledLink>
            )}
            {type !== 'signup' && <VersionInfoCard />}
          </GuidTextContainer>
        )}
        {!isAuth0 && <BrandingImg />}
        <LoginForm isAuth0={isAuth0} />
      </ContainerRight>
    </Container>
  );
}

LoginView.propTypes = {
  type: PropTypes.string
};

LoginView.defaultProps = {
  type: ''
};
export default LoginView;
