import { useEffect, useState, lazy } from 'react';
import { Routes, Route, Navigate, useBlocker, Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Helmet } from 'react-helmet';
import { pdfjs } from 'react-pdf';
import { useAuth0 } from '@auth0/auth0-react';
import { setGlobalAuth0Functions } from '@Utils/auth/authAccessor';

import { ENV } from '@Utils/envTools';
import { userInfoState, unsavedState, viewerState } from '@Utils/atoms';
import ProtectedRoute from '@Utils/ProtectedRoute';
import PasswordReseter from '@Components/Login/PasswordReseter';
import TaskView from '@Components/TaskView/TaskView';
import UserSettings from '@Components/UserSettings/UserSettings';
import {
  FullSpinner,
  Viewer,
  ConfirmModal,
  ViewerLauncher,
  AccessionLauncher
} from '@Components/NeosomaUI';
import AuthCallback from '@Components/Auth/AuthCallback';
import AuthFallback from '@Components/Auth/AuthFallback';
import { refreshScanTimestamp } from '@Utils/common';
import LoginView from '@Components/Login/LoginView';
import Page404 from './404';
// import TumorBoard from '../tumorBoard/TumorBoard';
import PatientSearch from '../patientSearch/PatientSearch';
import Navigation from './Navigation';
import Patients from '../patients/Patients';
import PatientPortal from '../patients/PatientPortal';
import DisclaimerModal from './DisclaimerModal';
import IdleTimeout from './IdleTimeout';
import CookieRefresh from './CookieRefresh';

const Auth0Invite = lazy(() =>
  import(/* webpackChunkName: "auth0-invite" */ './Auth0Invite')
);

// pdf.worker.min.mjs is copied during webpack deploy from node_modules/pdfjs-dist/build/
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

const ApplicationContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /*
    +-------------------------+ 
    |  fix for lastpass issue |
    +-------------------------+ 
  */
  &[data-lastpass-icon-root] {
    display: none !important;
  }
`;

const queryClient = new QueryClient();

// eslint-disable-next-line react/prop-types
function GetDivOrNavigate({ counter, userInfo }) {
  if (counter < 50) {
    // TODO: Add something better than an empty div here...
    return (
      <ProtectedRoute userInfo={userInfo}>
        <div />
      </ProtectedRoute>
    );
  }
  return <Navigate to="/login" replace />;
}

export default function App() {
  const userInfo = useRecoilValue(userInfoState) || {};
  const [{ unsaved: unsavedChanges }, setUnsavedChanges] =
    useRecoilState(unsavedState);
  const { visible: viwerOpen } = useRecoilValue(viewerState);
  const [showConfirm, setShowConfirm] = useState(false);
  const [configLoaded, setConfigLoaded] = useState(1);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();

  useEffect(() => {
    if (configLoaded > 0) {
      // TODO: Add some code here to check for "redirect" as the host - if so, redirect to the
      // "Last Known Domain", which needs to set a cookie whenever it loads the index page
      if (window.env.client_id !== undefined) {
        setConfigLoaded(0);
      } else if (configLoaded < 50) {
        setConfigLoaded(configLoaded + 1);
      }
    }
  }, [configLoaded]);

  // Set up global access to getAccessTokenSilently
  useEffect(() => {
    setGlobalAuth0Functions(getAccessTokenSilently, getIdTokenClaims);
  }, [getAccessTokenSilently, getIdTokenClaims]);

  // Block navigating when there is unsaved changes
  const blocker = useBlocker(
    ({ currentLocation: currLocation, nextLocation }) =>
      unsavedChanges && currLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    refreshScanTimestamp();
  }, []);

  // Effect to show the confirm modal when navigation is blocked
  useEffect(() => {
    if (blocker?.state === 'blocked') {
      !viwerOpen && setShowConfirm(true);
    }
    if (blocker?.state !== 'blocked') {
      setShowConfirm(false);
    }
    setUnsavedChanges({
      type: 'blocker',
      blocker: {
        state: blocker?.state,
        reset: blocker?.reset,
        proceed: blocker?.proceed
      }
    });
  }, [unsavedChanges, blocker, setUnsavedChanges, viwerOpen]);

  // Effect to hide the confirm modal if no more
  // unsaved changes and modal is still open
  useEffect(() => {
    if (!unsavedChanges && showConfirm) {
      setShowConfirm(false);
    }
  }, [showConfirm, unsavedChanges]);

  // Effect to hide the confirm modal if the viewer is open
  // as viwer has its own unsaved changes modal.
  useEffect(() => {
    if (viwerOpen && !showConfirm) {
      setUnsavedChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUnsavedChanges, viwerOpen]);

  const handleConfirmUnsaved = () => {
    setUnsavedChanges(false);
    setShowConfirm(false);
    if (blocker?.state === 'blocked') {
      blocker?.proceed();
    }
  };
  const handleCancelUnsaved = () => {
    setShowConfirm(false);
    if (blocker?.state === 'blocked') {
      blocker?.reset();
    }
  };

  return (
    <>
      <Helmet>
        <title>{ENV.DOMAIN_TITLE}</title>
      </Helmet>
      <ConfirmModal
        isShowing={showConfirm}
        onCancel={handleCancelUnsaved}
        onConfirm={handleConfirmUnsaved}
        title="Unsaved Changes"
        message={
          <>
            <div>Are you sure you want to leave this tab?</div>
            <div>You have unsaved changes that will be lost.</div>
          </>
        }
        confirmText="Discard and continue"
      />
      <FullSpinner />
      <ApplicationContainer>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route
              path="*"
              element={
                configLoaded > 0 ? (
                  <GetDivOrNavigate
                    counter={configLoaded}
                    userInfo={userInfo}
                  />
                ) : (
                  <>
                    <Helmet>
                      <title>{ENV.DOMAIN_TITLE}</title>
                    </Helmet>
                    <ProtectedRoute userInfo={userInfo}>
                      <Navigation>
                        <Routes>
                          <Route
                            path="/"
                            element={
                              <ProtectedRoute userInfo={userInfo}>
                                <TaskView />
                              </ProtectedRoute>
                            }
                          />
                          <Route path="/patients/*" element={<Patients />}>
                            <Route
                              path=":id"
                              element={
                                <ProtectedRoute userInfo={userInfo}>
                                  <PatientPortal />
                                </ProtectedRoute>
                              }
                            />
                          </Route>
                          <Route
                            path="/patient-search"
                            element={
                              <ProtectedRoute userInfo={userInfo}>
                                <PatientSearch />
                              </ProtectedRoute>
                            }
                          />
                          {/* <Route path="/tumor-board" element={<TumorBoard />} /> */}
                          <Route
                            path="/settings"
                            element={
                              <ProtectedRoute userInfo={userInfo}>
                                <UserSettings />
                              </ProtectedRoute>
                            }
                          />
                          <Route path="/viewer/*" element={<Outlet />}>
                            <Route
                              path=":study_id"
                              element={<ViewerLauncher />}
                            />
                            <Route path="*" element={<Page404 />} />
                          </Route>
                          <Route
                            path="/accession/:accession_number"
                            element={<AccessionLauncher />}
                          />
                        </Routes>
                      </Navigation>
                    </ProtectedRoute>
                  </>
                )
              }
            />
            <Route path="/login" element={<LoginView />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/auth/fallback" element={<AuthFallback />} />
            <Route path="/invite" element={<Auth0Invite />} />
            <Route path="/signup" element={<LoginView type="signup" />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
          <DisclaimerModal />
          <PasswordReseter />
          <Viewer />
          <IdleTimeout />
          <CookieRefresh />
        </QueryClientProvider>
      </ApplicationContainer>
    </>
  );
}
